import React from 'react';
import { Layout, Space, Typography } from 'antd';
import classes from './Footer.module.scss';
import { APP } from 'common/lib/constants/constants';
import { useIntl } from 'react-intl';

interface FooterProps extends React.ComponentProps<typeof Layout.Footer> {
}

const Footer: React.FC<FooterProps> = () => {
  const { formatMessage: t, locale } = useIntl();

  const getLocaleForLink = () => {
    const allowedLocales = ['de', 'es', 'lv', 'fr', 'ru', 'it'];
    return allowedLocales.includes(locale.toLowerCase()) ? locale.toLowerCase() : 'en';
  };
  const privacyLink = `https://www.weststein.pl/${getLocaleForLink()}/private/privacy-policy`;
  const termsLink = `https://www.weststein.pl/${getLocaleForLink()}/private/terms-and-conditions`;

  return (
    <Layout.Footer className={classes.container}>
      <Space size={16} wrap>
        <Typography.Text>© {APP.NAME} {(new Date()).getFullYear()}</Typography.Text>
        <Typography.Link href={termsLink}>{t({ id: 'mobile.screens.about.menu.termsConditions' })}</Typography.Link>
        <Typography.Link href={privacyLink}>{t({ id: 'mobile.screens.about.menu.privacyPolicy' })}</Typography.Link>
      </Space>
    </Layout.Footer>
  );
};

export default Footer;
