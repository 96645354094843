import React, { useState, FC } from 'react';
import { Modal, Typography, DatePicker, Space, Button, Radio, List } from 'antd';
import { useIntl } from 'react-intl';
import classes from '../../SignUp.module.scss';
import { ReactComponent as ExclamationIcon } from 'common/assets/svg/exclamation.svg';

type Props = {
  open: boolean;
  onClose: () => void;
  onOk: () => void;
  riskLevel: string
};

export const RiskModal: FC<Props> = ({ open, onClose, onOk, riskLevel }) => {
  const { formatMessage: t } = useIntl();

  return (
    <>
      <Modal
        // title={t({ id: 'sections.client.create.riskCountry.title' })}
        open={open}
        classNames={{
          header: classes.modalHeader
        }}
        footer={null}
        onCancel={onClose}>
          <ExclamationIcon className={classes.modalIcon}/>
          <Typography.Title level={4} style={{ margin: '20px 0 30px' }}>
          {t({ id: 'sections.client.create.riskCountry.title' })}
        </Typography.Title>
        <Typography.Title level={5} style={{ margin: '20px 0' }}>
          {t({ id: 'sections.client.create.riskCountry.list.title' })}
        </Typography.Title>
        <Typography.Paragraph>
          1.{t({ id: 'sections.client.create.riskCountry.list.1' })}
        </Typography.Paragraph>
        <Typography.Paragraph>
          2.{t({ id: 'sections.client.create.riskCountry.list.2' })}
        </Typography.Paragraph>
        <Typography.Paragraph>
          3.{t({ id: 'sections.client.create.riskCountry.list.3' })}
        </Typography.Paragraph>
        <Typography.Paragraph>
          4.{t({ id: 'sections.client.create.riskCountry.list.4' })}
        </Typography.Paragraph>
        <Typography.Paragraph style={{marginBottom: 15}}>
          5.{t({ id: 'sections.client.create.riskCountry.list.5' })}
        </Typography.Paragraph>
       {riskLevel === 'EXTREME' && <Typography.Paragraph>
          6.{t({ id: 'sections.client.create.riskCountry.list.6' })}
        </Typography.Paragraph>}
        <Button onClick={onOk} type="primary" block>
          {t({ id: 'screens.setting.setting.phone.buttons.confirm' })}
        </Button>
      </Modal>
    </>
  );
};