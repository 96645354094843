import React, { HTMLAttributes, MouseEventHandler, useEffect, useState } from 'react';
import {
  Button,
  Divider,
  Flex,
  Form,
  GetProps,
  Input,
  message,
  Modal,
  Result,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import PlanContainer from '../../../common/components/PlanContainer/PlanContainer';
import { useDispatch, useSelector } from 'react-redux';
import riverUserApi from '../../api/user';
import userApi from '../../../common/api/user';
import { AppDispatch, RootState } from '../../../common/store';
import classes from './RiverPlanContainer.module.scss';
import planContainerClasses from 'common/components/PlanContainer/PlanContainer.module.scss';
import Icon, {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  LoadingOutlined,
  WarningOutlined,
  PlusOutlined 
} from '@ant-design/icons';
import { COLORS } from '../../../common/lib/constants/theme';
import {
  ACCOUNT_STATUS,
  PLAN_NAME,
  VERIFICATION_STATUS,
} from '../../lib/constants/values';
import { Account, AccountStatus, VerificationStatus } from '../../types';
import SumsubWebSdk from '@sumsub/websdk-react';
import { useNavigate } from 'react-router';
import API from '../../api';
import { useIntl } from 'react-intl';
import { initRiver } from '../../store/actions';
import { BlackButton } from '../../../common/components/UI';
import { getAccounts } from '../../store/actions/user';
import { GTAG_EVENTS } from '../../../common/lib/tools/gtag_events';
import { useMessage } from '../../../common/lib/hooks/useMessage/useMessage';
import { general, generalFields } from '../../../common/lib/schemas/rules';
import { AddAccountDrawer } from '../AddAccountDrawer';

const verificationStatusMapping: Record<
  VerificationStatus,
  { title: string; Icon: () => React.ReactNode }
> = {
  [VERIFICATION_STATUS.STARTING]: {
    title: 'verification.status.not_in_progress',
    Icon: () => <WarningOutlined style={{ color: COLORS.WARNING, fontSize: 18 }} />,
  },
  [VERIFICATION_STATUS.NOT_STARTED]: {
    title: 'verification.status.not_in_progress',
    Icon: () => <WarningOutlined style={{ color: COLORS.WARNING, fontSize: 18 }} />,
  },
  [VERIFICATION_STATUS.INIT]: {
    title: 'verification.status.not_in_progress',
    Icon: () => <WarningOutlined style={{ color: COLORS.WARNING, fontSize: 18 }} />,
  },
  [VERIFICATION_STATUS.ONHOLD]: {
    title: 'verification.status.in_progress',
    Icon: () => <LoadingOutlined style={{ color: COLORS.INFO, fontSize: 18 }} />,
  },
  [VERIFICATION_STATUS.PENDING]: {
    title: 'verification.status.in_progress',
    Icon: () => <LoadingOutlined style={{ color: COLORS.INFO, fontSize: 18 }} />,
  },
  [VERIFICATION_STATUS.PRECHECKED]: {
    title: 'verification.status.in_progress',
    Icon: () => <LoadingOutlined style={{ color: COLORS.INFO, fontSize: 18 }} />,
  },
  [VERIFICATION_STATUS.QUEUED]: {
    title: 'verification.status.in_progress',
    Icon: () => <LoadingOutlined style={{ color: COLORS.INFO, fontSize: 18 }} />,
  },
  [VERIFICATION_STATUS.RETRY]: {
    title: 'verification.status.retry',
    Icon: () => <CloseCircleOutlined style={{ color: COLORS.ERROR, fontSize: 18 }} />,
  },
  [VERIFICATION_STATUS.DECLINED]: {
    title: 'verification.status.declined',
    Icon: () => <CloseCircleOutlined style={{ color: COLORS.ERROR, fontSize: 18 }} />,
  },
  [VERIFICATION_STATUS.COMPLETED]: {
    title: 'verification.status.successful',
    Icon: () => <CheckCircleOutlined style={{ color: COLORS.SUCCESS, fontSize: 18 }} />,
  },
  ERROR: {
    title: 'verification.status.error',
    Icon: () => <WarningOutlined style={{ color: COLORS.WARNING, fontSize: 18 }} />,
  },
} as const;

const accountStatusMapping: Record<
  AccountStatus,
  { title: string; Icon: () => React.ReactNode }
> = {
  [ACCOUNT_STATUS.NEW]: {
    title: 'verification.account.status.new',
    Icon: () => (
      <ExclamationCircleOutlined style={{ color: COLORS.INFO, fontSize: 18 }} />
    ),
  },
  [ACCOUNT_STATUS.PENDING]: {
    title: 'verification.account.status.pending',
    Icon: () => (
      <ExclamationCircleOutlined style={{ color: COLORS.INFO, fontSize: 18 }} />
    ),
  },
  [ACCOUNT_STATUS.REJECTED]: {
    title: 'verification.account.status.rejected',
    Icon: () => <CloseCircleOutlined style={{ color: COLORS.ERROR, fontSize: 18 }} />,
  },
  [ACCOUNT_STATUS.CLOSED]: {
    title: 'verification.account.status.closed',
    Icon: () => <CloseCircleOutlined style={{ color: COLORS.ERROR, fontSize: 18 }} />,
  },
} as const;

interface RiverPlanContainerProps extends HTMLAttributes<HTMLDivElement> {}

const RiverPlanContainer: React.FC<RiverPlanContainerProps> = (props) => {
  const [showSumSubModal, setShowSumSubModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showTinModal, setShowTinModal] = useState(false);

  const secured = useSelector((state: RootState) => state.user.securedRiver)
  const [loading, setLoading] = useState({
    active: false,
    message: '',
  });
  const { showSuccess, showError } = useMessage();
  const [openCreateAccountDrawer, setOpenCreateAccountDrawer] = useState(false);
  const [tinLoading, setTinLoading] = useState<boolean>(false);
  const [tinForm] = Form.useForm();
  const [tinFormData, setTinFormData] = useState<Partial<{ tin: string | null }>>();

  const [sumSubAccessToken, setSumSubAccessToken] = useState<string>('');
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { formatMessage: t } = useIntl();

  const user = useSelector((state: RootState) => state.user),
    userMeta = user.meta;

  const riverUser = useSelector((state: RootState) => state.river),
    accounts = Object.values(riverUser.accounts),
    riverMeta = riverUser.meta,
    totalBalance = accounts.reduce((sum, current) => sum + current.available, 0);
  const openSumSubModal = () => setShowSumSubModal(true);
  const closeSumSubModal = async () => {
    await fetchVerificationStatus();
    setShowSumSubModal(false);
  };
  const openInfoModal = () => setShowInfoModal(true);
  const closeInfoModal = () => setShowInfoModal(false);
  const openErrorModal = () => setShowErrorModal(true);
  const closeErrorModal = () => setShowErrorModal(false);
  const openTinModal = () => setShowTinModal(true);
  const closeTinModal = () => {
    setShowTinModal(false);
    tinForm.resetFields();
  };

  const toggleSecured = () => {
    dispatch({type: 'User/SECURITY_BALANCE_RIVER', payload: !secured})
  }

  useEffect(() => {
    fetchVerificationStatus();
  }, []);

  const handleContainerClick = async () => {
    setLoading((prevState) => ({
      ...prevState,
      active: true,
    }));
    try {
      const status = await fetchVerificationStatus();
      checkReviewStatus(status);
    } catch (err) {
      throw err;
    } finally {
      setLoading((prevState) => ({
        ...prevState,
        active: false,
      }));
    }
  };

  const checkReviewStatus = (status?: string) => {
    const reviewStatus = status ?? userMeta.verificationStatus;
    switch (reviewStatus) {
      case undefined:
      case VERIFICATION_STATUS.NOT_STARTED:
      case VERIFICATION_STATUS.INIT:
      case VERIFICATION_STATUS.PENDING:
      case VERIFICATION_STATUS.ONHOLD:
      case VERIFICATION_STATUS.RETRY:
        return (async () => {
          const token = await fetchSumSubToken();
          if (token) {
            openSumSubModal();
          }
        })();
      case VERIFICATION_STATUS.DECLINED:
        return openErrorModal();
      case VERIFICATION_STATUS.COMPLETED: {
        return onCompleteVerification();
      }
      default:
        return undefined;
    }
  };

  const fetchAccountStatus = async () => {
    try {
      const {
        data: {
          response: { status },
        },
      } = await API.user.getClientData();
      dispatch({
        type: 'User/SET_META',
        payload: { river: status },
      });
      return status;
    } catch (err) {
      throw err;
    }
  };

  const onCompleteVerification = async () => {
    try {
      const status = await fetchAccountStatus();

      checkAccountStatus(status);
    } catch (err: any) {
      if (err?.response?.status === 400) {
        return createMainAccount();
      } else throw err;
    }
  };

  const checkAccountStatus = (status?: string) => {
    const accountStatus = status ?? userMeta.river;

    switch (accountStatus) {
      case ACCOUNT_STATUS.NEW:
      case ACCOUNT_STATUS.PENDING:
        return openInfoModal();
      case ACCOUNT_STATUS.CLOSED:
      case ACCOUNT_STATUS.REJECTED:
        return openErrorModal();
      case ACCOUNT_STATUS.ACTIVE: {
        initRiver();
        return undefined;
      }
      default:
        return undefined;
    }
  };

  const fetchSumSubToken = async () => {
    try {
      const {
        data: { response },
      } = await riverUserApi.fetchVerificationSumSubToken();
      setSumSubAccessToken(response.token);
      return response.token;
    } catch (e) {
      const token = '';
      setSumSubAccessToken(token);
      return token;
    }
  };

  const createMainAccount = async (
    e?: React.MouseEvent<HTMLElement>,
    fromTinModal?: boolean,
  ) => {
    dispatch({ type: 'App/SHOW_LOADER' });
    try {
      const {
        data: {
          response: { status },
        },
      } = await API.user.createPrivateClient();
      await fetchVerificationStatus();
      await fetchAccountStatus();

      gtag('event', GTAG_EVENTS.SUMSUB_VERIFIED.name);

      setShowTinModal(false);
      showSuccess(t({ id: 'verification.accountCreate.success' }));
    } catch (e: any) {
      const error = e?.response?.data.errors[0],
        errStatus = e?.response?.status,
        errId = error?.id,
        errMsg = error?.variables?.['message: '];

      if (errStatus === 400) {
        if (
          error.variables &&
          error.variables['status: '] === '422' &&
          error.variables['uri: '] === '#/person_code'
        ) {
          if (fromTinModal) {
            tinForm.setFields([
              { name: 'tin', value: '', errors: [t({ id: 'fields.tin.error' })] },
            ]);
          } else {
            openTinModal();
          }
        } else if (
          error.variables['River API Error: '] === '/clients/person/' &&
          error.variables['errorType: '] === 'validation'
        ) {
          showError(t({ id: 'river.user.account.createRiverAccount.client.inProgress' }));
        } else {
          showError(t({ id: 'verification.accountCreate.error' }));
        }
      } else {
        if (errMsg) message.error(errMsg);
        else if (errId) {
          let msg = '';
          switch (errId) {
            case 'verification.status':
              msg = t({ id: 'screens.dashboard.createAccount.statusError' });
              break;
            default:
              msg = t({ id: 'modals.renew.error.title' });
          }
          showError(msg);
        }
      }
    } finally {
      dispatch({ type: 'App/HIDE_LOADER' });
    }
  };

  const onSubmitTin = async (values: { tin: string }) => {
    try {
      setTinLoading(true);
      await API.user.updateTin(values.tin);
      await createMainAccount(undefined, true);
    } catch (err) {
      showError(t({ id: 'common.message.error' }));
    } finally {
      setTinLoading(false);
    }
  };

  const renderItems = (accounts: Account[], secured?: boolean) => {
    return (
      <Space
        className="w100"
        split={<Divider type="horizontal" style={{ margin: 0 }} />}
        direction="vertical"
        size={0}
        wrap>
        {accounts.map((account, index: number) => {
          const balanceFixed = account.available ? account.available.toFixed(2) : '0.00';
          const balanceString = balanceFixed.replace('.', ',');
          const [wholePart, fractionalPart] = balanceString.split(',');
          return (
            <div
              className={classes.accountContainer}
              key={account.id}
              onClick={() => navigate(`/pro/${account.id}`)}>
              <Flex gap={4} align="center">
                <Typography.Text className={classes.title} ellipsis>
                  {account.title}
                </Typography.Text>
                {account.status !== ACCOUNT_STATUS.CLOSED ? (
                  <div
                    style={{
                      borderRadius: '50%',
                      backgroundColor: 'rgb(185, 227, 181)',
                      width: 16,
                      height: 16,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <CheckOutlined style={{ fontSize: 8 }} />
                  </div>
                ) : (
                  <div
                    style={{
                      borderRadius: '50%',
                      backgroundColor: 'rgb(246,176,176)',
                      width: 16,
                      height: 16,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <CloseOutlined style={{ fontSize: 8 }} />
                  </div>
                )}
              </Flex>
              <Typography.Text className={classes.balance} id={secured ? 'plan-balance-hidden' : 'plan-balance-shown'}>
                {secured ? '*' : wholePart},
                <Typography.Text className={classes.fractionalPart}>{secured ? '**' : fractionalPart} €</Typography.Text>
              </Typography.Text>
            </div>
          );
        })}
      </Space>
    );
  };
  const renderFooter = () => {
    if (
      userMeta.verificationStatus &&
      ![VERIFICATION_STATUS.COMPLETED, VERIFICATION_STATUS.NOT_STARTED].includes(
        userMeta.verificationStatus,
      )
    ) {
      const status = verificationStatusMapping[userMeta.verificationStatus];
      return (
        <div className={planContainerClasses.footer}>
          <div className={planContainerClasses.verificationContainer}>
            <Typography.Text className={planContainerClasses.text}>
              {t({ id: status.title })}
            </Typography.Text>
            <div className={planContainerClasses.actionsContainer}>
              {/*<Button type="primary" size="small" className={planContainerClasses.btn}>Повторить</Button>*/}
              <div className={planContainerClasses.iconContainer}>{status.Icon()}</div>
            </div>
          </div>
        </div>
      );
    }

    if (userMeta.river && userMeta.river !== ACCOUNT_STATUS.ACTIVE) {
      const status = accountStatusMapping[userMeta.river];
      return (
        <div className={planContainerClasses.footer}>
          <div className={planContainerClasses.verificationContainer}>
            <Typography.Text className={planContainerClasses.text}>
              {t({ id: status.title })}
            </Typography.Text>
            <div className={planContainerClasses.actionsContainer}>
              <div className={planContainerClasses.iconContainer}>{status.Icon()}</div>
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  const fetchVerificationStatus = async () => {
    setLoading({ active: true, message: t({ id: 'screens.dashboard.loading.status' }) });
    try {
      const {
        data: {
          response: { reviewStatus },
        },
      } = await riverUserApi.getAccountVerificationStatus();

      // first verification send === transition INIT -> PENDING
      if (
        reviewStatus === VERIFICATION_STATUS.PENDING &&
        userMeta.verificationStatus === VERIFICATION_STATUS.INIT
      )
        gtag('event', GTAG_EVENTS.SUMSUB_SEND.name);

      dispatch({ type: 'User/SET_META', payload: { verificationStatus: reviewStatus } });
      return reviewStatus;
    } catch (err: any) {
      const errStatus = err.response?.status;

      if (errStatus === 400) {
        dispatch({
          type: 'User/SET_META',
          payload: { verificationStatus: VERIFICATION_STATUS.NOT_STARTED },
        });
        return VERIFICATION_STATUS.NOT_STARTED;
      } else showError(t({ id: 'common.message.error' }));

      return '';
    } finally {
      setLoading({ active: false, message: '' });
    }
  };

  return (
    <React.Fragment>
      <PlanContainer
      verificationStatusFail={userMeta.river && userMeta.river !== ACCOUNT_STATUS.ACTIVE}
        setSecured={toggleSecured}
        onClick={handleContainerClick}
        footer={renderFooter}
        items={accounts}
        renderItems={renderItems}
        onCreateAccount={
          (userMeta.verificationStatus === VERIFICATION_STATUS.COMPLETED &&
            userMeta.river !== ACCOUNT_STATUS.NEW &&
            userMeta.river !== ACCOUNT_STATUS.REJECTED &&
            userMeta.river !== ACCOUNT_STATUS.CLOSED &&
            userMeta.river !== ACCOUNT_STATUS.PENDING) ||
          userMeta.verificationStatus === VERIFICATION_STATUS.INIT ||
          userMeta.verificationStatus === VERIFICATION_STATUS.NOT_STARTED
            ? () => createMainAccount
            : undefined
        }
        active={userMeta.river === ACCOUNT_STATUS.ACTIVE}
        benefits={
          userMeta.verificationStatus === undefined ||
          userMeta.verificationStatus === VERIFICATION_STATUS.INIT ||
          userMeta.verificationStatus === VERIFICATION_STATUS.NOT_STARTED
            ? [
                t({ id: 'river.user.account.benefits1' }),
                t({ id: 'river.user.account.benefits2' }),
                t({ id: 'river.user.account.benefits3' }),
                t({ id: 'river.user.account.benefits4' }),
                t({ id: 'river.user.account.benefits5' }),
                t({ id: 'river.user.account.benefits6' }),
              ]
            : []
        }
        availBal={totalBalance}
        loading={loading}
        gradient={['rgb(197, 241, 193)', 'rgb(185,227,181)']}
        title={PLAN_NAME} {...props}
        secured={secured}
        id='river'
      />
      <Modal footer={null} open={showErrorModal} onCancel={closeErrorModal} centered>
        <Result
          status="error"
          title={t({ id: 'verification.status.declined' })}
          subTitle={t({ id: 'verification.status.declined.desc' })}
        />
      </Modal>
      <Modal footer={null} open={showTinModal} onCancel={closeTinModal} centered>
        <Result
          status="error"
          title={t({ id: 'fields.tin.title' })}
          subTitle={t({ id: 'fields.tin.text' })}
          extra={
            <Form form={tinForm} initialValues={tinFormData} onFinish={onSubmitTin}>
              <Flex vertical gap={16}>
                <Form.Item
                  name="tin"
                  rules={[general(t)['required-field'], general(t)['max-length'](32)]}>
                  <Input
                    disabled={tinLoading}
                    placeholder={t({ id: 'fields.tin.placeholder' })}
                  />
                </Form.Item>
                <BlackButton disabled={tinLoading} type="primary" htmlType="submit">
                  Update
                </BlackButton>
              </Flex>
            </Form>
          }
        />
      </Modal>
      <Modal footer={null} open={showSumSubModal} onCancel={closeSumSubModal} centered>
        <SumsubWebSdk
          accessToken={sumSubAccessToken}
          expirationHandler={fetchSumSubToken}
        />
      </Modal>
      <Modal footer={null} open={showInfoModal} onCancel={closeInfoModal} centered>
        <Result
          status="info"
          title={t({ id: 'verification.status.in_progress' })}
          subTitle={t({ id: 'verification.status.in_progress.desc' })}
        />
      </Modal>
      <AddAccountDrawer
        open={openCreateAccountDrawer}
        onClose={() => setOpenCreateAccountDrawer(false)}
      />

      {(accounts.length && accounts.length  < 5 && userMeta.river === ACCOUNT_STATUS.ACTIVE) ? (
        <button onClick={() => setOpenCreateAccountDrawer(true)} className={classes.addAccount}>
        <PlusOutlined/>
      </button>
      ) : null}
    </React.Fragment>
  );
};

export default React.memo(RiverPlanContainer);
