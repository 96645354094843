import React from "react";
import { Modal, Result } from "antd";

type ForbiddenModalProps = {
  open: boolean,
  onClose: () => unknown,
  message: string
}

const ForbiddenModal: React.FC<ForbiddenModalProps> = ({open, onClose, message}) => {
  return (
    <Modal footer={null} open={open} onCancel={onClose} centered>
      <Result title={message} status='error'/>
    </Modal>
  )
}

export default ForbiddenModal