import {API} from "common/http";
import { ENDPOINTS } from '../lib/constants/values';
import { ApiResponse } from "common/types/app";
import {
  ConfirmValidateUpdateParams,
  ValidateUpdateBody,
  ValidateUpdateResponse,
} from './dto/validation';

type UserApi = {
  auth: {
    login: (email: string, password: string, google2faCodeFromUser?: string) => Promise<any>;
    confirmTwoFactor: (userId: number, email: string, code: string) => Promise<any>;
    resetPassword: (email: string) => Promise<any>;
    resetPasswordConfirm: (token: string, form: {email: string, password: string}) => Promise<any>;
    createAccount: (formData: {
      address: {
        city: string,
        country: number,
        line1: string,
        line2?: string,
        postalCode: string
      },
      dateOfBirth: string,
      email: string,
      firstName: string,
      'g-recaptcha-response': string,
      gender: string,
      language: string,
      lastName: string,
      password: string,
      phone: string,
      phoneCountry: number,
      phoneVerified?: string,
      privacyPolicy: boolean,
      registrationSource?: string,
      termsAndConditions: boolean,
      agreeMonitored: boolean,
      utm: Record<string, string>,
    }) => Promise<any>;
    validateEmail: (email: string) => Promise<any>;
    sendGeolocation: () => void;
    sendGeolocationPosition: () => void;
    confirmAccountReview: () => Promise<any>;
    sendEmailCode: () => Promise<any>;
    sendPhoneCode: () => Promise<any>;
    verifyEmail: (code: string) => Promise<any>;
    verifyPhone: (code: string) => Promise<any>;
    createCardPayment: () => Promise<any>;
    createContactPayPayment: () => Promise<any>;
    createMultihubPayment: () => Promise<any>;
    createUSDTPayment: () => Promise<any>;
    createTrongridPayment: () => Promise<any>;
    checkCardPayment: (paymentId: number, cardholderId: string) => Promise<any>;
    checkContactPayPayment: (paymentId: number) => Promise<any>;
    checkMultihubPayment: (paymentId: number) => Promise<any>;
    checkUSDTPayment: (paymentId: number) => Promise<any>;
    checkTrongridPayment: (paymentId: number) => Promise<any>;
    logout: () => Promise<any>;
  },
  info: {
    getUser: () => Promise<any>;
    getUserInfo: () => Promise<any>;
    handleHotelClick: (cardholderId: string) => Promise<any>;
    getReferralCode: () => Promise<any>;
    updateRestoreId: (restoreId: string) => Promise<any>;
    getAddress: () => Promise<any>;
    getRechargeCards: () => Promise<any>;
    deleteRechargeCard: (cardId: number) => Promise<any>;
  },
  settings: {
    getAvatar: () => Promise<any>;
    setAvatar: (file: FormData) => Promise<any>;
    deleteAvatar: () => Promise<any>;
    enableBiometrics: () => Promise<any>;
    disableBiometrics: () => Promise<any>;
    disableBiometricsNotification: () => Promise<any>;
    changePassword: (oldPass: string, newPass: string) => Promise<any>;
    getLastPhoneChangeRequest: () => Promise<any>;
    getAllChangeRequests: () => Promise<any>;
    requestPhoneChange: (phoneCountry: number, phone: string, reason?: string) => Promise<ApiResponse<any>>;
    requestEmailChange: (email: string, reason?: string) => Promise<ApiResponse<any>>;
    confirmPhoneChange: (requestId: string, code?: string) => Promise<ApiResponse<any>>;
    confirmEmailChange: (requestId: string) => Promise<ApiResponse<any>>;
    resendPhoneChange: (requestId: string) => Promise<any>;
    setNotificationsForIncomingTransactions: (value: boolean) => Promise<any>;
    setLanguage: (language: string) => Promise<any>;
    enableTwoFactor: (code: string) => Promise<any>;
    disableTwoFactor: (code?: string) => Promise<any>;
    disableTwoFactorNotification: () => Promise<any>;
    deleteUser: () => Promise<any>;
    reportProblem: (formData: FormData) => Promise<any>;
    updateValidation: (
      body: ValidateUpdateBody,
    ) => Promise<ApiResponse<ValidateUpdateResponse>>;
    resendUpdateValidation: (id: string | number) => Promise<ApiResponse<any>>;
    confirmUpdateValidation: (
      id: string | number,
      params: ConfirmValidateUpdateParams,
    ) => Promise<ApiResponse<ValidateUpdateResponse>>;
  };
};

const userApi: UserApi = {
  auth: {
    login: (email, password, google2faCodeFromUser) =>
      API.post(ENDPOINTS.USER_CTRL.AUTH.SIGN_IN, {}, {
        params: { email, password, google2faCodeFromUser }
      }),
    confirmTwoFactor: (userId, email, code) =>
      API.post(ENDPOINTS.USER_CTRL.AUTH.CONFIRM_TWO_FACTOR, {}, {
        params: { userId, email, code }
      }),
    resetPassword: (email) =>
      API.post(ENDPOINTS.USER_CTRL.AUTH.RESET_PASSWORD, {}, {
        params: { email }
      }),
    resetPasswordConfirm: (token, form) =>
      API.post(ENDPOINTS.USER_CTRL.AUTH.RESET_PASSWORD_CONFIRM(token), {}, {
        params: form
      }),
    createAccount: (formData) =>
      API.post(ENDPOINTS.USER_CTRL.AUTH.CREATE_ACCOUNT, formData),
    validateEmail: (email) => API.post(ENDPOINTS.USER_CTRL.AUTH.VALIDATE_EMAIL, {}, {
      params: {
        email,
      }
    }),
    sendGeolocation: () => {},
    sendGeolocationPosition: () => {},
    confirmAccountReview: () =>
      API.post(ENDPOINTS.USER_CTRL.AUTH.CONFIRM_ACCOUNT_REVIEW),
    sendEmailCode: () => API.get(ENDPOINTS.USER_CTRL.AUTH.SEND_EMAIL_CODE),
    sendPhoneCode: () => API.get(ENDPOINTS.USER_CTRL.AUTH.SEND_PHONE_CODE),
    verifyEmail: (code) => API.post(ENDPOINTS.USER_CTRL.AUTH.VERIFY_EMAIL(code)),
    verifyPhone: (code) => API.post(ENDPOINTS.USER_CTRL.AUTH.VERIFY_PHONE, {}, {
      params: {
        code,
        language: 'EN',
      }
    }),
    createCardPayment: () => API.post(ENDPOINTS.USER_CTRL.AUTH.CREATE_CARD_PAYMENT),
    createContactPayPayment: () => API.post(ENDPOINTS.USER_CTRL.AUTH.CREATE_CONTACTPAY_PAYMENT),
    createMultihubPayment: () => API.post(ENDPOINTS.USER_CTRL.AUTH.CREATE_MULTIHUB_PAYMENT),
    createUSDTPayment: () => API.post(ENDPOINTS.USER_CTRL.AUTH.CREATE_USDT_PAYMENT),
    createTrongridPayment: () => API.post(ENDPOINTS.USER_CTRL.AUTH.CREATE_TRONGRID_PAYMENT),
    checkCardPayment: (paymentId, cardholderId) => API.post(ENDPOINTS.USER_CTRL.AUTH.CHECK_CARD_PAYMENT(paymentId, cardholderId)),
    checkContactPayPayment: (paymentId) => API.post(ENDPOINTS.USER_CTRL.AUTH.CHECK_CONTACTPAY_PAYMENT(paymentId)),
    checkMultihubPayment: (paymentId) => API.post(ENDPOINTS.USER_CTRL.AUTH.CHECK_MULTIHUB_PAYMENT(paymentId)),
    checkUSDTPayment: (paymentId) => API.post(ENDPOINTS.USER_CTRL.AUTH.CHECK_USDT_PAYMENT(paymentId)),
    checkTrongridPayment: (paymentId) => API.post(ENDPOINTS.USER_CTRL.AUTH.CHECK_TRONGRID_PAYMENT(paymentId)),
    logout: () => API.post(ENDPOINTS.USER_CTRL.AUTH.LOG_OUT),
  },
  info: {
    getUser: () => API.get(ENDPOINTS.USER_CTRL.INFO.GET_USER),
    getUserInfo: () => API.get(ENDPOINTS.USER_CTRL.INFO.GET_USER_INFO),
    getReferralCode: () => API.get(ENDPOINTS.USER_CTRL.INFO.GER_REFERRAL_CODE),
    updateRestoreId: (restoreId) => API.put(ENDPOINTS.USER_CTRL.INFO.UPDATE_RESTORE_ID, {}, {
      params: {
        restoreId,
      }
    }),
    getAddress: () => API.get(ENDPOINTS.USER_CTRL.INFO.GET_ADDRESS),
    getRechargeCards: () => API.get(ENDPOINTS.USER_CTRL.INFO.GET_RECHARGE_CARDS),
    deleteRechargeCard: (cardId) => API.delete(ENDPOINTS.USER_CTRL.INFO.DELETE_RECHARGE_CARD(cardId)),
    handleHotelClick: (cardholderId) => API.post(ENDPOINTS.USER_CTRL.INFO.HOTEL_CLICK(cardholderId)),
  },
  settings: {
    getAvatar: () => API.get(ENDPOINTS.USER_CTRL.SETTINGS.AVATAR, {
      responseType: 'blob',
    }),
    setAvatar: (file) => API.post(ENDPOINTS.USER_CTRL.SETTINGS.AVATAR, file , {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }),
    deleteAvatar: () => API.delete(ENDPOINTS.USER_CTRL.SETTINGS.AVATAR),
    enableBiometrics: () => API.post(ENDPOINTS.USER_CTRL.SETTINGS.BIOMETRICS.ENABLE),
    disableBiometrics: () => API.post(ENDPOINTS.USER_CTRL.SETTINGS.BIOMETRICS.DISABLE),
    disableBiometricsNotification: () => API.post(ENDPOINTS.USER_CTRL.SETTINGS.BIOMETRICS.DISABLE_NOTIFICATION),
    changePassword: (oldPassword, newPassword) => API.post(ENDPOINTS.USER_CTRL.SETTINGS.CHANGE_PASSWORD, {}, {
      params: {
        oldPassword,
        newPassword,
      }
    }),
    getLastPhoneChangeRequest: () => API.get(ENDPOINTS.USER_CTRL.SETTINGS.GET_PHONE_CHANGE_LAST_REQUEST),
    getAllChangeRequests: () => API.get(ENDPOINTS.USER_CTRL.SETTINGS.GET_CHANGE_REQUESTS),
    requestPhoneChange: (phoneCountry, phone, reason) => API.post(ENDPOINTS.USER_CTRL.SETTINGS.REQUEST_DATA_CHANGE, {
      phoneCountry,
      phone,
      reason
    }),
    requestEmailChange: (email, reason) => API.post(ENDPOINTS.USER_CTRL.SETTINGS.REQUEST_DATA_CHANGE, {
      email,
      reason
    }),
    confirmPhoneChange: (requestId, code) => API.get(ENDPOINTS.USER_CTRL.SETTINGS.CONFIRM_DATA_CHANGE(requestId), {
      params: code ? {code} : undefined
    }),
    confirmEmailChange: (requestId) => API.get(ENDPOINTS.USER_CTRL.SETTINGS.CONFIRM_DATA_CHANGE(requestId)),
    resendPhoneChange: (requestId) => API.put(ENDPOINTS.USER_CTRL.SETTINGS.RESEND_REQUEST_PHONE_CHANGE(requestId)),
    setNotificationsForIncomingTransactions: (value) => API.put(ENDPOINTS.USER_CTRL.SETTINGS.PUSH_NOTIFICATIONS_FOR_INCOMING_TRANSACTIONS, {}, {
      params: {
        incomingTransactions: value,
      }
    }),
    setLanguage: (language) => API.put(ENDPOINTS.USER_CTRL.SETTINGS.SET_LANGUAGE, {}, {
      params: {
        language,
      }
    }),
    enableTwoFactor: (code) => API.post(ENDPOINTS.USER_CTRL.SETTINGS.TWO_FACTOR.ENABLE, {}, {
      params: {
        code,
      }
    }),
    disableTwoFactor: (code) => API.post(ENDPOINTS.USER_CTRL.SETTINGS.TWO_FACTOR.DISABLE, {}, {
      params: {
        code,
      }
    }),
    disableTwoFactorNotification: () => API.post(ENDPOINTS.USER_CTRL.SETTINGS.TWO_FACTOR.DISABLE_NOTIFICATION),
    deleteUser: () => API.delete(ENDPOINTS.USER_CTRL.SETTINGS.DELETE_USER),
    reportProblem: (formData) =>
      API.post(ENDPOINTS.USER_CTRL.SETTINGS.REPORT_PROBLEM, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    updateValidation: (body) =>
      API.post(ENDPOINTS.USER_CTRL.SETTINGS.VALIDATION.UPDATE, body),
    resendUpdateValidation: (id) =>
      API.get(ENDPOINTS.USER_CTRL.SETTINGS.VALIDATION.UPDATE_RESEND(id)),
    confirmUpdateValidation: (id, params) =>
      API.post(
        ENDPOINTS.USER_CTRL.SETTINGS.VALIDATION.UPDATE_CONFIRM(id),
        {},
        { params: params },
      ),
  },
};

export default userApi;
