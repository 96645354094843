import { ThunkAction } from 'redux-thunk';
import { RootState } from 'common/store/index';
import { AnyAction } from 'redux';
import appActions from 'common/api/app';
import { Country, Currency } from 'common/types/app';
import { Dispatch } from 'react';

export const getCurrencies = (): ThunkAction<Promise<Currency[]>, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const currencies = getState().app.currencies;
    if (currencies.length > 0) return currencies;
    else {
      try {
        const { data: { response } } = await appActions.getCurrencies();
        dispatch({ type: 'App/SET_CURRENCIES', payload: response });
        return response;
      } catch (e) {
        return [];
      }
    }
  };

export const convertCurrency = (cardId: number, body: {amount: number, currencyFrom: string, currencyTo: string}): ThunkAction<Promise<{amount: number; currentFrom: string | null; currentTo: string | null}>, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const { data: { response } } = await appActions.convertCurrency(cardId, body);
      return response;
    } catch (e) {
      throw e;
    }
  };

export const getCountries = (): ThunkAction<Promise<Country[]>, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const countries = getState().app.countries;
    if (countries.length > 0) return countries;
    else {
      try {
        const { data: { response } } = await appActions.getCountries();
        dispatch({ type: 'App/SET_COUNTRIES', payload: response });
        return response;
      } catch (e) {
        return [];
      }
    }
  };

export const showLoader = (payload?: {
  message?: string;
  type: 'transparent' | 'backdrop';
}) => ({ type: 'App/SHOW_LOADER', payload });

export const hideLoader = () => ({ type: 'App/HIDE_LOADER' });