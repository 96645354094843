import { Flex, Form, FormInstance, Input, InputNumber, MenuProps, Typography } from 'antd';
import classes from '../Sepa.module.scss';
import React, { useState } from 'react';
import { Account } from '../../../../types';
import { getSendSepaScheme } from '../model/scheme';
import { SepaForm } from '../model/types';
import AccountDropdown from '../../../../components/AccountDropdown/AccountDropdown';
import { BlackButton } from 'common/components/UI';
import { useIntl } from 'react-intl';
import { Template } from "../../../../types/templates";

const { Title, Text } = Typography;
const { TextArea } = Input;

type BeneficiaryProps = {
  fromOptions: MenuProps['items'];
  currentFrom: Account;
  formValues: Partial<SepaForm>;
  form: FormInstance;
  template?: Template;
  onCancel: () => unknown;
}

const Beneficiary: React.FC<BeneficiaryProps> = ({
                                                   fromOptions,
                                                   currentFrom,
                                                   formValues,
                                                   form,
                                                   template,
                                                   onCancel,
                                                 }) => {
  const { formatMessage: t } = useIntl();
  const rules = getSendSepaScheme(t);
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string[] }>({
    beneficiary: [],
    iban: [],
    amount: [],
    description: [],
  });

  const onFieldsChange = () => {
    setValidationErrors(prevState => ({
      ...prevState,
      beneficiary: form.getFieldError('beneficiary'),
      iban: form.getFieldError('iban'),
      amount: form.getFieldError('amount'),
      description: form.getFieldError('description'),
    }))
  }

  return (
    <Flex vertical gap={12}>
      <Title level={3}>{t({ id: 'screens.payment.create.beneficiary.title' })}</Title>
      <Flex vertical gap={24}>
        <AccountDropdown
          label={`${t({ id: 'noty.card.from' })}:`}
          options={fromOptions}
          currentOption={currentFrom}
          id='account'
        />
        <Form initialValues={formValues} form={form} onFieldsChange={onFieldsChange} name="beneficiary">
          <Flex vertical gap={8}>
            <Text>{t({ id: 'noty.card.to' })}:</Text>
            <Flex vertical>
              <Form.Item
                help={<div id="error__beneficiary">{validationErrors.beneficiary[0]}</div>}
                initialValue={template ? template.recipientName : undefined}
                name="beneficiary"
                rules={rules.beneficiary}
              >
                <Input
                  size="large"
                  placeholder={t({ id: 'screens.payment.create.beneficiary.inputs.beneficiary' })}
                />
              </Form.Item>
              <Form.Item
                help={<div id="error__iban">{validationErrors.iban[0]}</div>}
                initialValue={template ? template.iban : undefined}
                name="iban"
                rules={rules.iban}
              >
                <Input
                  size="large"
                  placeholder={t({ id: 'screens.payment.create.beneficiary.inputs.iban' })}
                />
              </Form.Item>
            </Flex>
          </Flex>
          <Flex vertical>
            <Form.Item
              help={<div id="error__amount">{validationErrors.amount[0]}</div>}
              initialValue={template ? template.amount : undefined}
              name="amount"
              rules={rules.amount}
            >
              <InputNumber
                addonBefore="€"
                placeholder={t({ id: 'screens.payment.create.beneficiary.inputs.amount' })}
                rootClassName={classes['input']}
              />
            </Form.Item>
            <Text style={{ fontSize: 14 }}>
              {t({ id: 'screens.payment.create.beneficiary.inputs.amount.info3' })}
            </Text>
          </Flex>
          <Form.Item
            help={<div id="error__description">{validationErrors.description[0]}</div>}
            initialValue={template ? template.description : undefined}
            name="description"
            rules={rules.description}
            rootClassName={classes['description']}
          >
            <TextArea
              placeholder={t({ id: 'screens.payment.create.beneficiary.inputs.details' })}
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </Form.Item>
          <Flex gap={8}>
            <BlackButton
              htmlType="submit"
              type="primary"
              id='btn__send-sepa-submit'
            >
              {t({ id: 'buttons.send' })}
            </BlackButton>
            <BlackButton
              onClick={onCancel}
            >
              {t({ id: 'button.simple.cancel' })}
            </BlackButton>
          </Flex>
        </Form>
      </Flex>
    </Flex>
  );
};

export default Beneficiary;