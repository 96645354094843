import { Rule } from 'antd/es/form';
import { validateClientAddress } from './validators';

export const general = (t: (descriptor: object, values?: object) => string) =>
  ({
    'only-letters': (name: string) => ({
      pattern: /^[A-Za-z]+$/,
      message: t({ id: 'schemas.alphabetic' }, {name}),
    }),
    'only-numbers': {
      pattern: /^[0-9]+$/,
      message: t({ id: 'schemas.new.only-numbers' }),
    },
    'numbers-and-letters-and-spaces': {
      pattern: /^[A-Za-z0-9\s]+$/,
      message: t({ id: 'schemas.new.numbers-and-letters-and-spaces' }),
    },
    'numbers-and-spaces': {
      pattern: /^[0-9\s]+$/,
      message: t({ id: 'schemas.new.numbers-and-spaces' }),
    },
    'letters-and-spaces': {
      pattern: /^[A-Za-z\s]+$/,
      message: t({ id: 'schemas.new.letters-and-spaces' }),
    },
    'numbers-and-letters': {
      pattern: /^[A-Za-z0-9]+$/,
      message: t({ id: 'schemas.new.numbers-and-letters' }),
    },
    'required-field': {
      required: true,
      message: t({ id: 'schemas.new.required-field' }),
    },
    'max-length': (maxLength: number) => ({
      max: maxLength,
      message: t({ id: 'schemas.new.max-length' }, { maxLength }),
    }),
    'min-length': (minLength: number) => ({
      min: minLength,
      message: t({ id: 'schemas.new.min-length' }, { minLength }),
    }),
    'must-match':
      (fieldName: string, fieldTranslate?: string) =>
      ({ getFieldValue }: { getFieldValue: any }) => ({
        validator(_: any, value: any) {
          if (!value || getFieldValue(fieldName) === value) {
            return Promise.resolve();
          }
          return Promise.reject(
            new Error(
              fieldTranslate
                ? t({ id: 'schemas.match' }, { name: fieldTranslate })
                : t({ id: 'schemas.new.fields.invalid-characters' }),
            ),
          );
        },
      }),
  }) as const;

export const generalFields = (t: (descriptor: object, values?: object) => string) =>
  ({
    name: [
      general(t)['min-length'](2),
      general(t)['max-length'](20),
      general(t)['only-letters'](t({id:'inputs.name'})),
    ],
    account: [
      general(t)['min-length'](2),
      general(t)['max-length'](20),
      general(t)['letters-and-spaces'],
    ],
    surname: [
      general(t)['min-length'](2),
      general(t)['max-length'](20),
      general(t)['only-letters'](t({id:'inputs.surname'})),
    ],
    email: [
      {
        pattern:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: t({ id: 'schemas.new.fields.email' }),
      },
      {
        pattern: /^(?!.*\.ru$).*/,
        message: t({ id: 'schemas.email.invalid.ru' }),
      }
    ],
    city: [
      general(t)['min-length'](2),
      general(t)['max-length'](35),
      {
        pattern: /^[A-Za-z0-9\s&()/,.-]+$/,
        message: t({ id: 'schemas.alphanumeric.address' }, {name: t({id:'inputs.city'})}),
      },
    ],
    address: [
      general(t)['min-length'](2),
      general(t)['max-length'](70),
      {
        pattern: /^[A-Za-z0-9\s&()/,.-]+$/,
        message: t({ id: 'schemas.alphanumeric.address' }, {name: t({id:'inputs.address'})}),
      },
      validateClientAddress(t)
    ],
    postalCode: [
      general(t)['min-length'](2),
      general(t)['max-length'](10),
      {
        pattern: /^[A-Za-z0-9-_.,"&\s]+$/,
        message: t({ id: 'schemas.postalCode.alphanumeric' }, {name: t({id: 'inputs.postalCode'})}),
      },
    ],
    phoneNumber: [
      general(t)['only-numbers'],
      general(t)['min-length'](5),
      general(t)['max-length'](15),
    ],
    password: [
      general(t)['min-length'](8),
      general(t)['max-length'](20),
      {
        pattern: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-z0-9$&+,:;=?@#|'"`~<>.^*()%!-_]+)$/,
        message: t({ id: 'schemas.new.fields.password.alphanumeric' }),
      },
    ],
    beneficiary: [
      general(t)["min-length"](2),
      general(t)["max-length"](20),
      general(t)["letters-and-spaces"],
    ],
    iban: [
      general(t)["min-length"](15),
      general(t)["max-length"](31),
      general(t)["numbers-and-letters"]
    ],
    amount: [
      () => ({
        validator(_: any, value: any) {
          if (value <= 0)
            return Promise.reject(t({id: 'schemas.greater.than'}))
          else
            return Promise.resolve();
        }
      })
    ],
    '3ds': [
      general(t)['min-length'](4),
      general(t)['max-length'](140),
      {
        pattern: /^[A-Za-z0-9_.\-,"&()!%?;:#$*+/= ]+$/,
        message: t({ id: 'schemas.new.fields.invalid-characters' }),
      },
    ] as Rule[],
    pin: [
      general(t)['min-length'](4),
      general(t)['max-length'](4),
    ] as Rule[],
  }) as const;
