import React, { useRef, useState } from 'react';
import { Button, Divider, Drawer, Flex, Form, Input, Segmented, Typography } from 'antd';
import { hideLoader, showLoader } from 'common/store/actions/app';

import { useDispatch, useSelector } from 'react-redux';
import { signVirtualCard } from 'river/store/actions/user';
import { getAccountAddressString, getCurrentAccount } from 'river/store/selectors';
import { RiverThunkDispatch } from 'river/types/thunk';
import classes from './DrawerAddCard.module.css';
import { useIntl } from 'react-intl';
import CodeInput from 'common/components/UI/CodeInput/CodeInput';
import cardApi from 'river/api/card';
import { general, generalFields } from 'common/lib/schemas/rules';
import FormItem from 'antd/es/form/FormItem';
import { BlackButton } from 'common/components/UI';
import { useMessage } from 'common/lib/hooks/useMessage/useMessage';
import { getErrorDefaultMessage } from 'common/lib/utils/helpers';
import { refreshRiver } from 'river/store/actions';

type PlasticFormProps = { onClose: () => unknown };

const PlasticForm: React.FC<PlasticFormProps> = ({ onClose }) => {
  const { showSuccess, showError } = useMessage();
  const { formatMessage: t } = useIntl();
  const account = useSelector(getCurrentAccount) || 0;
  const dispatch: RiverThunkDispatch = useDispatch();

  const address = useSelector(getAccountAddressString);
  const incorrectAddressMessage = t(
    { id: 'screens.pro.createCard.plastic.address.incorrect' },
    {
      email: '#',
    },
  ).split('#');

  const [form] = Form.useForm();
  const validationScheme = {
    pin: [general(t)['required-field'], ...generalFields(t)['pin']],
  };

  const onCreate = ({ pin }: { pin: string }) => {
    dispatch(showLoader());
    cardApi
      .applyForPCCard(account, {
        cardDeliveryMethodId: 'DEUTSCHE_POST_PACKET',
        pin,
        location: 'GER_1',
      })
      .then(() => {
        dispatch(refreshRiver());
        showSuccess(t({ id: 'screens.pro.createCard.plastic.created' }));
        onClose();
      })
      .catch((error) => {
        showError(getErrorDefaultMessage(error) || t({ id: 'common.message.error' }));
      })
      .finally(() => {
        dispatch(hideLoader());
      });
  };

  return (
    <Flex vertical gap={16}>
      <Form form={form} onFinish={onCreate} validateTrigger="onChange">
        <FormItem rules={validationScheme.pin} name="pin">
          <Input maxLength={4} placeholder="PIN" />
        </FormItem>
        <Flex vertical gap={2}>
          <Typography.Text>
            {t({ id: 'screens.pro.createCard.plastic.address.text' })}
          </Typography.Text>
          <Typography.Text strong>{address}</Typography.Text>
          <Typography.Text className={classes.hint} type="warning">
            {incorrectAddressMessage[0]}
            <a className={classes.link} href="mailto:cardservices@weststeincard.com">
              cardservices@weststeincard.com
            </a>
            {incorrectAddressMessage[1]}
          </Typography.Text>
        </Flex>
      </Form>
      <BlackButton block className={classes.button} onClick={form.submit}>
        {t({ id: 'screens.card.create.buttons.create' })}
      </BlackButton>
    </Flex>
  );
};

export default PlasticForm;
