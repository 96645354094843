import React, { useEffect, useState } from 'react';
import { Col, Form, Input, Row, Select } from 'antd';
import { Country } from 'common/types/app';
import { general, generalFields } from 'common/lib/schemas/rules';
import { useIntl } from 'react-intl';
import parsePhoneNumberFromString from 'libphonenumber-js';

export type PhoneInputState = {
  countryCode: number;
  phoneNumber: string;
  haveErrors: boolean;
};
type PhoneInputProps = {
  countries: Country[];
  onChange: (state: PhoneInputState) => unknown;
};


const PhoneInput: React.FC<PhoneInputProps> = ({ countries, onChange }) => {
  const [value, setValue] = useState<PhoneInputState>({
    countryCode: countries[0]?.id || -1,
    phoneNumber: '',
    haveErrors: true,
  });
  const { formatMessage: t } = useIntl();
  const [form] = Form.useForm();
  const [validationErrors, setValidationErrors] = useState<{[key: string]: string[]}>({
    phoneCountry: [],
    phoneNumber: []
  })

  const onFieldsChange = () => {
    setValidationErrors(prevState => ({
      ...prevState,
      phoneCountry: form.getFieldError('countryCode'),
      phoneNumber: form.getFieldError('phoneNumber'),
    }))
  }

  const phoneNumberValidator = async (_: any, value: string) => {
    const countryId = form.getFieldValue('countryCode');
    const country = countries.find(c => c.id === countryId);
    if (!country) {
      return Promise.reject(new Error(t({id: "schemas.validation.phone.country.required"})));
    }
    const phoneNumber = parsePhoneNumberFromString(`+${country.phoneCode}${value}`);

    if (!phoneNumber || !phoneNumber.isValid()) {
      return Promise.reject(new Error(t({id: "schemas.validation.phone.invalid"})));
    }
    return Promise.resolve();
  };
  const onChangeCountryCode = (value: number) => {
    setValue((prev) => ({
      ...prev,
      countryCode: value,
    }));
  };

  const onChangePhoneNumber = (number: string) => {
    setValue((prev) => ({
      ...prev,
      phoneNumber: number,
    }));
  };

  const validationScheme = {
    countryCode: [general(t)['required-field']],
    phoneNumber: [
      general(t)['required-field'],
      ...generalFields(t).phoneNumber,
      { validator: phoneNumberValidator },
    ],
  };

  React.useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => onChange({ ...value, haveErrors: false }))
      .catch(() => onChange({ ...value, haveErrors: true }));
  }, [form, onChange, value]);

  const filterOption = (input: string, option?: { label: string; value: string }) => {
    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Form form={form} validateTrigger='onBlur' style={{ width: '100%' }} onFieldsChange={onFieldsChange}>
      <Row gutter={8}>
        <Col span={10}>
          <Form.Item help={
            <div id='error__phone-country'>{validationErrors.phoneCountry[0]}</div>
          } rules={validationScheme.countryCode} name="countryCode">
            <Select
              showSearch
              filterOption={filterOption}
              placement="bottomLeft"
              value={value.countryCode}
              dropdownMatchSelectWidth={false}
              optionLabelProp="label"
              onChange={onChangeCountryCode}
              placeholder="+000"
              style={{height: 55}}
            >
              {countries.map((country, idx) => (
                <Select.Option 
                id={`phone-country${idx}`} 
                // optionLabelProp={'shortLabel'} 
                label={`+${country.phoneCode}`} 
                value={country.id} 
                key={country.id}>
                  <div style={{ display: 'flex' }} id={`phone-country${idx}`}>
                  <div
                    className={`fi fi-${country.iso2.toLowerCase()}`}
                    style={{ marginRight: 6, flex: 'none' }}
                  />
                  <div
                    id={`phone-country${idx}-text`}>{`+${country.phoneCode}\t${country.name}`}</div>
                </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={14}>
          <Form.Item help={
            <div id='error__phone-number'>{validationErrors.phoneNumber[0]}</div>
          } rules={validationScheme.phoneNumber} name="phoneNumber">
            <Input
            style={{height: 55}}
              placeholder="000-00-00"
              value={value.phoneNumber}
              onChange={(event) => {
                onChangePhoneNumber(event.target.value);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default PhoneInput;
