import React from 'react';
import { Flex, Modal, Result, Typography } from 'antd';
import { BlackButton } from 'common/components/UI';
import { useIntl } from 'react-intl';

type ReplaceModalProps = {
  isOpen: boolean;
  isVirtual: boolean;
  virtualPrice: number;
  plasticPrice: number;
  onClose: () => unknown;
  onReplace: () => unknown;
};

const ReplaceModal: React.FC<ReplaceModalProps> = ({
  isOpen,
  isVirtual,
  virtualPrice,
  plasticPrice,
  onReplace,
  onClose,
}) => {
  const { formatMessage: t } = useIntl();

  const priceString = isVirtual
    ? t({ id: 'card.blocked.replace.alert.desc.virtual' }, { amount: virtualPrice })
    : t({ id: 'card.blocked.replace.alert.desc.plastic' }, { amount: plasticPrice });

  return (
    <Modal title="&nbsp;" open={isOpen} footer={false} onCancel={onClose}>
      <Result
        status="warning"
        title={t({ id: 'card.blocked.replace.alert.title' })}
        subTitle={
          <>
            <Typography.Text>
              {t({ id: 'card.blocked.replace.alert.desc' })}
            </Typography.Text>
            <br />
            <Typography.Text>{priceString}</Typography.Text>
          </>
        }
        extra={
          <Flex gap={8} vertical>
            <BlackButton type="primary" key="replace" block onClick={onReplace}>
              {t({ id: 'card.blocked.replace' })}
            </BlackButton>
            <BlackButton key="cancel" onClick={onClose} block>
              {t({ id: 'button.simple.cancel' })}
            </BlackButton>
          </Flex>
        }
      />
    </Modal>
  );
};

export default ReplaceModal;
