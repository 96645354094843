import React, { useEffect, useState } from 'react';
import classes from './SignIn.module.scss';
import { Button, Flex, Form, Input, message, Space, Typography } from 'antd';
import CustomCardContainer from "common/components/UI/CustomCardContainer/CustomCardContainer";
import {APP} from "common/lib/constants/constants";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "common/components/AppRouter/AppRouter";
import {useDispatch} from "react-redux";
import {login} from "common/pages/Auth/SignIn/actions";
import {AppDispatch} from "common/store";
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import { getSignInScheme } from './model/scheme';
import { useIntl } from 'react-intl';
import { useMessage } from "../../../lib/hooks/useMessage/useMessage";
import ForbiddenModal from "./ForbiddenModal";

const SignIn = () => {
    const [loading, setLoading] = useState(false);
    const [showForbiddenModal, setShowForbiddenModal] = useState<boolean>(false);
    const [forbiddenMessage, setForbiddenMessage] = useState<string>('');

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { formatMessage: t } = useIntl();
    const dispatch = useDispatch<AppDispatch>();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const password = Form.useWatch('password', form);
    const formRules = getSignInScheme(t);
    const { showError, showSuccess } = useMessage();
    const [emailErr, setEmailErr] = useState<string[]>([])
    const [passwordErr, setPasswordErr] = useState<string[]>([])

    const onFinish = ({email, password}: { email: string, password: string }) => {
        setLoading(true);
        dispatch(login({ email, password }))
          .then((response: any) => {
              if (response?.route) navigate(response.route);
          })
          .catch((error: any) => {
            if (error?.response?.headers?.['content-type']?.includes('text/html')) {
              showError(t({id: 'screens.login.errors.authError'}));
            } else {
              const errorId = error?.response?.data?.errors?.[0]?.id;
              const messageId  = error?.response?.data?.messages?.[0]?.id;
              const errorMessage = error?.response?.data?.errors?.[0]?.defaultMessage;
              switch (errorId) {
                case 'screens.auth.login.errors.invalid': {
                  if(messageId === 'google.2fa.incorrect'){
                    navigate(ROUTES.TWO_FACTOR.path, { state: { email, password }});
                    break;
                  }
                  if (errorMessage === 'BAD_CREDENTIALS')
                    showError(
                      t({id: 'screens.auth.login.errors.invalid'}),
                      'toast__credentials-invalid'
                    );
                  else
                    setShowForbiddenModal(true);
                    setForbiddenMessage(errorMessage);

                  break;
                }
                case 'google.2fa.not.provided': {
                  navigate(ROUTES.TWO_FACTOR.path, { state: { email, password }});
                  break;
                }
                case 'errors.access.temporary.blocked': {
                  showError(
                    t({ id: 'errors.access.temporary.blocked' }),
                    'toast__temporary-blocked'
                  );
                  break;
                }
                default: {
                  showError(
                    t({id:'modals.renew.error.title'}),
                    'toast__something-went-wrong'
                  );
                }
              }
            }
          })
          .finally(() => {
            setLoading(false);
          });
    };

    const onFieldsChange = () => {
      setEmailErr(form.getFieldError('email'))
      setPasswordErr(form.getFieldError('password'))
    }

    return (
        <div className={classes.wrapper}>
          <ForbiddenModal
            open={showForbiddenModal}
            onClose={() => setShowForbiddenModal(false)}
            message={forbiddenMessage}
          />
            <Flex flex={1} justify="center" align="center">
                <CustomCardContainer className={classes.card}>
                    <Typography.Text className={classes.companyName}>{APP.NAME}</Typography.Text>
                    <Form form={form} onFinish={onFinish} onFieldsChange={onFieldsChange}>
                      <Form.Item help={<div id='error__email'>{emailErr[0]}</div>} rules={formRules.email} name="email">
                            <Input placeholder={t({ id: 'inputs.email' })}/>
                        </Form.Item>
                        <Form.Item
                          help={<div id='error__password'>{passwordErr[0]}</div>}
                          rules={formRules.password} name="password">
                            <Input.Password visibilityToggle={{ onVisibleChange: () => {
                                if (!password) navigate(ROUTES.RESET_PASSWORD.path);
                                }}} iconRender={(visible) => {
                                if (password) {
                                    return visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                                } else return <Typography.Link>{t({ id: 'mobile.login.input.forgot' })}</Typography.Link>
                            }} placeholder={t({ id: 'inputs.password' })}/>
                        </Form.Item>
                        <Space direction="vertical" className="w100" size={8}>
                            <Button id='btn__login' loading={loading} type="primary" onClick={form.submit} block>{t({ id: 'buttons.login' })}</Button>
                            <Button id='btn__signup' disabled={loading} onClick={() => navigate(ROUTES.SIGN_UP.path)} type="text" block>{t({ id: 'buttons.signup' })}</Button>
                        </Space>
                    </Form>
                </CustomCardContainer>
            </Flex>
        </div>
    );
};

export default SignIn;
