import { Button, Flex, Modal, Result, Space, Typography, message } from 'antd';
import { BackButton, BlackButton, Card, GridContainer } from 'common/components/UI';
import { getLinearGradientString } from 'common/lib/utils/helpers';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { COLORS as RIVER_COLORS } from 'river/lib/constants/styles';
import { CARD_TYPE } from 'river/lib/constants/values';
import { getCards } from 'river/store/selectors';

import ReasonBlock from './blocks/Reason/Reason';
import cardApi from 'river/api/card';
import { BlockReason } from 'river/types';
import { updateCard } from 'river/store/actions/cards';
import WarningModal from './modals/WarningModal';
import SuccessModal from './modals/SuccessModal';
import { useIntl } from 'react-intl';
import { useMessage } from 'common/lib/hooks/useMessage/useMessage';
import classes from './BlockCard.module.css';

const BlockCard = () => {
  const { formatMessage: t } = useIntl();
  const { id: accountId, cardId } = useParams();
  const navigate = useNavigate();
  const [selected, setSelected] = useState<BlockReason | null>(null);
  const [warningModal, setWarningModal] = useState(false);
  const [successModal, setSuccesModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { showError, showSuccess } = useMessage();
  const cards = useSelector(getCards);
  const currentCard = cards.find((card) => String(card.id) === cardId);

  const isVirtual = currentCard?.type === CARD_TYPE.VIRTUAL;

  const cardColor = getLinearGradientString(RIVER_COLORS.MAIN.GRADIENT);
  const shortNumber = currentCard?.numberMasked.slice(-4) || '';

  const onBlock = () => {
    if (accountId && cardId && selected) {
      setLoading(true);
      cardApi
        .hardBlockCard(accountId, cardId, selected)
        .then(({ data: { response } }) => {
          dispatch(updateCard(accountId, response));
          onCloseWarning();
          onOpenSuccess();
        })
        .catch(() => {
          showError(t({ id: 'common.message.error' }));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onOpenWarning = () => {
    setWarningModal(true);
  };
  const onCloseWarning = () => {
    setWarningModal(false);
  };

  const onOpenSuccess = () => {
    setSuccesModal(true);
  };
  const onCloseSuccess = () => {
    setSuccesModal(false);
  };

  useEffect(() => {
    if (!currentCard) {
      navigate(-1);
    }
  }, [currentCard, navigate]);

  if (!currentCard) return;
  return (
    <>
      <GridContainer>
        <Space direction="vertical" size={16}>
          <BackButton />
          <Typography.Text className={classes.title}>
            {t({id: "card.block.title"})}
          </Typography.Text>
          <Space size={16}>
            <Card bgColor={cardColor} shortNumber="" />
            <Typography.Text className={classes.text}>
              {isVirtual ? 'Virtual card' : 'Plastic card'} *{shortNumber}
            </Typography.Text>
          </Space>
          <Typography.Text className={classes.text}>
          {t({id: "card.block.description"})}
          </Typography.Text>
        </Space>
        <ReasonBlock selected={selected} onSelect={setSelected} />
        <BlackButton
          type="primary"
          disabled={selected === null}
          block
          id='btn__block-request'
          onClick={onOpenWarning}>
          {t({id: "card.block.button"})}
        </BlackButton>
      </GridContainer>
      <WarningModal
        isOpen={warningModal}
        isLoading={isLoading}
        onClose={onCloseWarning}
        onBlock={onBlock}
      />
      <SuccessModal
        isOpen={successModal}
        onClose={onCloseSuccess}
        shortNumber={shortNumber}
      />
    </>
  );
};

export default BlockCard;
