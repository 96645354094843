import Container from '../../components/UI/Container/Container';
import { Flex, notification, Segmented, Typography } from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';
import Notification from './Notification';
import classes from './Notifications.module.scss';
import { CenteredContainer } from '../../components/UI';
import { useDispatch, useSelector } from 'react-redux';
import { getNotifications, markAsRead } from '../../store/actions/notifications';
import { AppDispatch } from '../../store';
import { EventType, NotificationType } from '../../types/notifications';
import { useIntl } from 'react-intl';
import { eventTypes } from './model/constants';
import moment from 'moment';
import { getUserLocale } from '../../store/selectors/app';
import { handleLangFormat } from '../../lib/utils/lang';

const { Text, Title } = Typography;

const Notifications: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { formatMessage: t } = useIntl();
  const availableFilters = [...Object.values(eventTypes).map(obj => ({
    label: <div id={`notification-label__${obj.value.toLowerCase()}`}>{t({ id: obj.label })}</div> as ReactNode,
    value: obj.value
  }))];
  // const availableFilters = [...Object.values(eventTypes).map(obj => ({
  //   label: t({ id: obj.label }),
  //   value: obj.value
  // }))];
  const [notifications, setNotifications] = useState<NotificationType[]>([]);
  const [unreadAmount, setUnreadAmount] = useState<number>(0);
  const lang = handleLangFormat(useSelector(getUserLocale));
  const formatFilter = (obj: {label: string, value: string}) => (
    {
      label: <div id={`notification-label__${obj.value}`}>{t({ id: obj.label })}</div> as ReactNode,
      value: obj.value
    }
  )
  const [filter, setFilter] = useState(formatFilter(eventTypes.all));

  useEffect(() => {
    moment.locale(lang);
  }, [lang]);

  useEffect(() => {
    fetchNotifications();
  }, [filter]);

  useEffect(() => {
    markAllAsRead();
  }, []);

  const fetchNotifications = async () => {
    let eventType: EventType | undefined = undefined;

    if (filter.value !== eventTypes.all.value) {
      eventType = Object.values(eventTypes).find(value => value.value === filter.value)?.value as EventType;
    }

    const response = await dispatch(getNotifications(eventType));
    setNotifications(response.notifications);
    setUnreadAmount(response.unreaded);
  };

  const markAllAsRead = async () => {
    if (unreadAmount > 0) {
      const unreadNotifications = notifications.filter(
        notification => notification.unread,
      );
      const unreadNotificationIds = unreadNotifications.map(notification => notification.id);
      try {
        await Promise.all(unreadNotificationIds.map(notificationId => dispatch(markAsRead(notificationId))));
      } catch (e) {
        //
      }
    }
  };

  const handleChange = (value: EventType) => {
    const filter = availableFilters.find(f => f.value === value);
    setFilter(filter ?? eventTypes.all);
  }

  return (
    <CenteredContainer>
      <Flex className={classes.wrapper} vertical gap={20}>
        <Title level={2}>{t({ id: 'screens.setting.setting.setting.nav.notification' })}</Title>
        <Segmented
          size='small'
          options={availableFilters}
          className={classes.segmented}
          onChange={(value) => handleChange(value as EventType)}
        />
        {notifications.length === 0 &&
          <Flex>
            <Text style={{ opacity: 0.6 }}>{t({ id: 'components.table.table.empty' })}</Text>
          </Flex>
        }
        {notifications.length !== 0 &&
          <Flex vertical gap={16}>
            {notifications.map(notification =>
              <Notification
                key={notification.id}
                title={notification.parameters.title}
                text={notification.parameters.text}
                date={notification.date}
              />,
            )}
          </Flex>
        }
      </Flex>
    </CenteredContainer>
  );
};

export default Notifications;