import { CARD_UNIVERSAL_STATUS } from 'common/lib/constants/constants';
import { Card, CardStatus } from 'river/types';
import { CARD_STATUS, CARD_TYPE } from '../constants/values';
import { UniversalCard, UniversalCardStatus } from 'common/types/cards';
import moment from 'moment';

export const getCardUniversalStatus = (
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  cardStatus: CardStatus | undefined,
): UniversalCardStatus => {
  switch (cardStatus) {
    case CARD_STATUS.SOFT_BLOCKED:
      return CARD_UNIVERSAL_STATUS.FROZEN;
    case CARD_STATUS.BLOCKED:
      return CARD_UNIVERSAL_STATUS.BLOCKED;
    case CARD_STATUS.APPLICATION:
      return CARD_UNIVERSAL_STATUS.APPLICATION;
    case CARD_STATUS.CLOSED:
      return CARD_UNIVERSAL_STATUS.CLOSED;
    case CARD_STATUS.SUSPENDED:
      return CARD_UNIVERSAL_STATUS.SUSPENDED;
    case CARD_STATUS.INACTIVE:
      return CARD_UNIVERSAL_STATUS.INACTIVE;
    default:
      return CARD_UNIVERSAL_STATUS.ACTIVE;
  }
};

export const getUniversalCard = (card: Card): UniversalCard => ({
  cvv: card.cvv || '•••',
  expDate: moment(card.expirationDate).format('MM/DD') || '',
  id: card.id,
  isVirtual: card.type === CARD_TYPE.VIRTUAL,
  number: card.numberFull
    ? card.numberFull.match(/.{1,4}/g)!.join(' ')
    : card.numberMasked,
  universalCardStatus: getCardUniversalStatus(card.status),
  enrolled: card.isEnrolled === 1,
});
