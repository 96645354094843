import React, { HTMLAttributes, useDebugValue, useEffect, useState } from 'react';
import { Button, GetProps, Space, Tooltip, Typography } from 'antd';
import PlanContainer from '../../../common/components/PlanContainer/PlanContainer';
import { useDispatch, useSelector } from 'react-redux';
import userApi from '../../api/user';
import { RootState } from '../../../common/store';
import { BLOCKING, CARD_STATUS, PLAN_NAME, VERIFICATION_STATUS } from '../../lib/constants/values';
import { CardIcon } from '../../../common/components';
import classes from './OceanPlanContainer.module.scss';
import planContainerClasses from 'common/components/PlanContainer/PlanContainer.module.scss';
import { CardInfo, CardStatus } from '../../types/cards';
import Icon, { CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined, WarningOutlined } from '@ant-design/icons';
import { COLORS } from '../../../common/lib/constants/theme';
import { VerificationStatus } from '../../types/user';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

const verificationStatusMapping: Record<VerificationStatus, { title: string, Icon: () => React.ReactNode }> = {
  [VERIFICATION_STATUS.NOT_IN_PROGRESS]: {
    title: 'verification.status.not_in_progress',
    Icon: () => <WarningOutlined style={{ color: COLORS.WARNING, fontSize: 18 }} />,
  },
  [VERIFICATION_STATUS.IN_PROGRESS]: {
    title: 'verification.status.in_progress',
    Icon: () => <LoadingOutlined style={{ color: COLORS.INFO, fontSize: 18 }} />,
  },
  [VERIFICATION_STATUS.FAILED]: {
    title: 'verification.status.failed',
    Icon: () => <CloseCircleOutlined style={{ color: COLORS.ERROR, fontSize: 18 }} />,
  },
  [VERIFICATION_STATUS.SUCCESS]: {
    title: 'verification.status.successful',
    Icon: () => <CheckCircleOutlined style={{ color: COLORS.SUCCESS, fontSize: 18 }} />,
  },
} as const;

interface OceanPlanContainerProps extends HTMLAttributes<HTMLDivElement> {

}

const OceanPlanContainer: React.FC<OceanPlanContainerProps> = (props) => {
  const [loading, setLoading] = useState({
    active: false,
    message: '',
  });
  const [verificationStatus, setVerificationStatus] = useState<VerificationStatus>();
  const secured = useSelector((state: RootState) => state.user.securedOcean)
  const user = useSelector((state: RootState) => state.user),
    userMeta = user.meta, userCards = user.cards,
    totalBalance = userCards.reduce((sum, current) => sum + current.availBal, 0);
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { formatMessage: t } = useIntl();

  const toggleSecured = () => {
    dispatch({type: 'User/SECURITY_BALANCE_OCEAN', payload: !secured})
  }

  // Функция на refresh данных (карты, верификация)
  const handleContainerClick = () => {
    // При статусе верификации любом кроме SUCCESS - запрос на ссылку UUID
    // При SUCCESS - выбор первой карты как текущей и перенаправление на экран с настройками карты
  }
  const onCardClick = (cardId: number) => {
    // Запрашиваем информацию по карте, обновляем state, занося также и currentCard
  }
  const renderItems = (cards: CardInfo[], secured?: boolean) => {
    return (
      <Space className={classes.cardsContainer} align="center" direction="horizontal" size="small" wrap>
        {cards.map((card, index: number) => (
          <div onClick={() => navigate(`/standart`)} key={card.id}>
            <Tooltip title={`${card.availBal} €`}>
              <CardIcon onClick={() => onCardClick(card.id)} error={card.cardStatus === BLOCKING.STATUS.DEPOSIT_ONLY} disabled={!BLOCKING.STATUS.ACTIVE_CARDS.includes(card.cardStatus)} cardNumber={secured ? '*****' : card.cardNumber} title={PLAN_NAME} />
            </Tooltip>
          </div>
        ))}
      </Space>
    )
  }
  const renderFooter = () => {
    if (verificationStatus && verificationStatus !== VERIFICATION_STATUS.SUCCESS) {
      const verification = verificationStatusMapping[verificationStatus];
      return (
        <div className={planContainerClasses.footer}>
          <div className={planContainerClasses.verificationContainer}>
            <Typography.Text className={planContainerClasses.text}>{t({id:verification.title})}</Typography.Text>
            <div className={planContainerClasses.actionsContainer}>
              {/*<Button type="primary" size="small" className={planContainerClasses.btn}>Повторить</Button>*/}
              <div className={planContainerClasses.iconContainer}>
                {verification.Icon()}
              </div>
            </div>
          </div>
        </div>
      )
    }
    return null;
  }

  const fetchVerificationStatus = async () => {
    const firstCard = userCards[0];
    if (firstCard && firstCard.cardholderId) {
      setLoading({ active: true, message: t({id:'screens.dashboard.loading.status'}) });
      try {
        const { data: { response: { VID_Status } }} = await userApi.verification.getVerificationStatus(firstCard.cardholderId);
        setVerificationStatus(VID_Status);
      } catch (e) {
        console.log({e});
      } finally {
        setLoading({ active: false, message: '' });
      }
    }
  }

  useEffect(() => {
    fetchVerificationStatus();
    // Запрос карт
  }, []);

  if (userCards && userCards.length > 0 && !['PRE_REQUEST', 'CARD_ISSUE_REVIEW', null].includes(userCards[0]?.cardStatus)) return (
    <PlanContainer footer={renderFooter} 
    verificationStatusFail={verificationStatus && verificationStatus !== VERIFICATION_STATUS.SUCCESS}
    setSecured={toggleSecured}
    secured={secured}
    items={userCards} 
    renderItems={renderItems} 
    active={Boolean(userMeta.id)} 
    benefits={[
      t({id:'ocean.user.account.benefits1'}),
      t({id:'ocean.user.account.benefits2'}),
      t({id:'ocean.user.account.benefits3'}),
      t({id:'ocean.user.account.benefits4'}),
      t({id:'ocean.user.account.benefits5'}),
      t({id:'ocean.user.account.benefits6'}),
      t({id:'ocean.user.account.benefits7'}),
    ]} availBal={totalBalance} loading={loading} gradient={['rgb(224, 204, 251)', 'rgb(195, 194, 251)']} title={PLAN_NAME} {...props}/>
  );

  return null;
};

export default React.memo(OceanPlanContainer);