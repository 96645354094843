import { AppDispatch } from '../index';
import notificationsApi from '../../api/notifications';
import { EventType } from '../../types/notifications';
import { NotificationType } from '../../types/notifications';
import { hideLoader, showLoader } from './app';

export const getNotifications = (
  eventType?: EventType,
  dateFrom?: string,
  dateTo?: string,
  offset?: number,
  limit?: number
): (dispatch: AppDispatch) => Promise<{ notifications: NotificationType[]; count: number; unreaded: number }> =>
  async (dispatch) => {
    try {
      dispatch(showLoader());
      const { data: { response } } = await notificationsApi.get(eventType, dateFrom, dateTo, offset, limit);

      return response;
    } catch (e) {
      throw e;
    } finally {
      dispatch(hideLoader());
    }
  };

export const markAsRead = (
 notificationId: string | number
): (dispatch: AppDispatch) => Promise<{ notifications: NotificationType[]; count: number; unreaded: number }> =>
  async (dispatch) => {
    try {
      const { data: { response } } = await notificationsApi.setReadStatus(notificationId);

      return response;
    } catch (e) {
      throw e;
    }
  };