import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useIntl } from 'react-intl';
import { useMessage } from 'common/lib/hooks/useMessage/useMessage';

import { getLinearGradientString } from 'common/lib/utils/helpers';
import { CARD_UNIVERSAL_STATUS, FILE_FORMAT, PLANS } from 'common/lib/constants/constants';
import { hideLoader, showLoader } from 'common/store/actions/app';
import CardsLayout from 'common/components/layout/CardsLayout/Cards';

import { getCards, getZipCode } from 'ocean/store/selectors';
import { getUniversalCard } from 'ocean/lib/utils/cards';
import { MAIN_GRADIENT } from 'ocean/lib/constants/colors';
import GetPINModal from './modals/GetPINModal';
import GetCVVModal from './modals/GetCVVModal';
import ManageCard from './blocks/ManageCard/ManageCard';
import cardsApi from 'ocean/api/cards';
import { updateCard } from 'ocean/store/actions/cards';
import { CARD_STATUS } from 'ocean/lib/constants/values';
import FreezeModal from 'common/modals/FreezeModal';
import transferApi from '../../api/transfer';
import moment from 'moment';
import { getUserLocale } from '../../../common/store/selectors/app';

const Cards = () => {
  const { formatMessage: t } = useIntl();
  const { cardId = '' } = useParams();
  const { showSuccess, showError } = useMessage();
  const [modals, setModals] = useState({
    pin: false,
    cvv: false,
    freeze: false,
  });
  const [drawers, setDrawers] = useState({
    transactions: false,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const zipCode = useSelector(getZipCode);
  const cards = useSelector(getCards);
  const language = useSelector(getUserLocale);

  const formattedCards = cards.map((card) => getUniversalCard(card));

  const [currentCardIndex, setCurrentCardIndex] = useState(
    formattedCards.findIndex(({ id }) => id === +cardId),
  );
  const currentCardObject = formattedCards[currentCardIndex];
  const currentCardFromStore = cards.find(({ id }) => id === +cardId);
  const isFrozen = currentCardObject.universalCardStatus === CARD_UNIVERSAL_STATUS.FROZEN;
  const isVirtual = currentCardObject.isVirtual;

  const onChangeSelectedCard = (index: number) => {
    setCurrentCardIndex(index);
    const currentId = currentCardObject.id;
    navigate('/standart/card/' + currentId);
  };

  const onStatementDownload = async ({
    dateRange,
    format = FILE_FORMAT.PDF,
  }: {
    dateRange: Array<string>;
    format: 'pdf' | 'csv';
  }) => {
    try {
      const response = await transferApi.statement.download(Number(currentCardObject.id), {
        start: moment(dateRange[0], 'YYYY-MM-DD').format('DD-MM-YYYY'),
        end: moment(dateRange[1], 'YYYY-MM-DD').format('DD-MM-YYYY'),
        format,
        language,
      });
      if (response.status === 200 && response.data) {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: `application/${format.toLowerCase()}` }),
        );
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.setAttribute('download', `statement.${format.toLowerCase()}`);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(url);
      } else {
        console.error('Ошибка получения файла: ', response.status);
      }
    } catch (e) {
      //
      console.log({ e });
    }
  }

  //#region GetPIN
  const onOpenSendPINModal = () => setModals((prev) => ({ ...prev, pin: true }));
  const onCloseSendPINModal = () => setModals((prev) => ({ ...prev, pin: false }));
  const onSendPIN = () => {
    if (currentCardFromStore && currentCardFromStore.availBal > 0) {
      dispatch(showLoader());
      cardsApi
        .getPin(currentCardObject.id)
        .then(() => {
          onCloseSendPINModal();
          showSuccess(t({ id: 'common.message.success' }));
        })
        .catch(() => {
          showError(t({ id: 'common.message.error' }));
        })
        .finally(() => {
          dispatch(hideLoader());
        });
    } else {
      showError(t({ id: 'screens.card.single.single.tabs.cvv.error' }));
    }
  };
  //#endregion

  //#region GetCVV
  const onOpenSendCVVModal = () => setModals((prev) => ({ ...prev, cvv: true }));
  const onCloseSendCVVModal = () => setModals((prev) => ({ ...prev, cvv: false }));
  const onSendCVV = () => {
    if (currentCardFromStore && currentCardFromStore.availBal > 0 && zipCode) {
      dispatch(showLoader());
      cardsApi
        .sendCVV(currentCardObject.id, { zipCode })
        .then(() => {
          onCloseSendPINModal();
          showSuccess(t({ id: 'common.message.success' }));
        })
        .catch(() => {
          showError(t({ id: 'common.message.error' }));
        })
        .finally(() => {
          dispatch(hideLoader());
        });
    } else {
      showError(t({ id: 'screens.card.single.single.tabs.cvv.error' }));
    }
  };
  //#endregion

  //#region Freeze
  const onOpenFreezeModal = () => setModals((prev) => ({ ...prev, freeze: true }));
  const onCloseFreezeModal = () => setModals((prev) => ({ ...prev, freeze: false }));
  const onFreeze = () => {
    if (currentCardFromStore) {
      dispatch(showLoader());
      cardsApi
        .block(currentCardObject.id)
        .then(() => {
          dispatch(
            updateCard({ ...currentCardFromStore, cardStatus: CARD_STATUS.DEPOSIT_ONLY }),
          );
          onCloseFreezeModal();
          showSuccess(t({ id: 'common.message.success' }));
        })
        .catch(() => {
          showError(t({ id: 'common.message.error' }));
        })
        .finally(() => {
          dispatch(hideLoader());
        });
    } else {
      showError(t({ id: 'common.message.error' }));
    }
  };
  const onUnfreeze = () => {
    if (currentCardFromStore) {
      dispatch(showLoader());
      cardsApi
        .unblock(currentCardObject.id)
        .then(() => {
          dispatch(updateCard({ ...currentCardFromStore, cardStatus: CARD_STATUS.OPEN }));
          showSuccess(t({ id: 'common.message.success' }));
        })
        .catch(() => {
          showError(t({ id: 'common.message.error' }));
        })
        .finally(() => {
          dispatch(hideLoader());
        });
    } else {
      showError(t({ id: 'common.message.error' }));
    }
  };
  //#endregion

  const onOpenTransactions = () => {
    setDrawers((prevState) => ({
      ...prevState,
      transactions: true,
    }));
  };
  const onCloseTransactions = () => {
    setDrawers((prevState) => ({
      ...prevState,
      transactions: false,
    }));
  };

  const fetchCardInfo = useCallback(() => {
    if (currentCardFromStore && !currentCardFromStore.numberFull)
      cardsApi
      .getCardNumber(+cardId)
      .then(({ data: { response: cardInfo } }) => {
        dispatch(
          updateCard({
            ...currentCardFromStore,
            numberFull: String(cardInfo.cardNumber)
          })
        );
      }).catch(err => {
        showError(t({ id: 'common.message.error' }))
      })
  }, [currentCardFromStore, cardId, dispatch]);

  useEffect(() => {
    if (!cardId || !cards.find(({ id }) => String(id) === String(cardId))) {
      navigate(-1);
      return;
    }
    fetchCardInfo();
  }, [cardId, cards, dispatch, fetchCardInfo, navigate]);

  return (
    <>
      <CardsLayout
        planName={PLANS.OCEAN}
        cards={formattedCards}
        color={getLinearGradientString(MAIN_GRADIENT)}
        currentCardIndex={currentCardIndex}
        isOpenTransactions={drawers.transactions}
        onOpenTransactions={onOpenTransactions}
        onCloseTransactions={onCloseTransactions}
        options={
          <ManageCard
            isVirtual={isVirtual}
            onGetPIN={onOpenSendPINModal}
            onSendCVV={onOpenSendCVVModal}
          />
        }
        showError={showError}
        showSuccess={showSuccess}
        onFreeze={onOpenFreezeModal}
        onUnfreeze={onUnfreeze}
        onChangeSelectedCard={onChangeSelectedCard}
        onStatementDownload={onStatementDownload}
      />
      <GetPINModal isOpen={modals.pin} onClose={onCloseSendPINModal} onSend={onSendPIN} />
      <GetCVVModal isOpen={modals.cvv} onClose={onCloseSendCVVModal} onSend={onSendCVV} />
      <FreezeModal
        isOpen={modals.freeze}
        onClose={onCloseFreezeModal}
        onFreeze={onFreeze}
      />
    </>
  );
};

export default Cards;
