export const ENDPOINTS = {
  GET_COUNTRIES: 'open/country',
  GET_CURRENCIES: 'open/currency',
  CONVERT_CURRENCY: (cardId: number) =>
    `private/payment/${cardId}/international/currency`,
  NOTIFICATIONS_CTRL: {
    GET_LIST: 'notification/get',
    GET_UNREAD_COUNT: 'notification/getUnreadCount',
    ADD_TOKEN: (cardholderId: string) => `notification/${cardholderId}/addToken`,
    DELETE_TOKEN: (cardholderId: string) => `notification/${cardholderId}/deleteToken`,
    SET_READ_STATUS: (notificationId: string | number) =>
      `notification/${notificationId}/read`,
  },
  USER_CTRL: {
    AUTH: {
      SIGN_IN: 'auth/login',
      CONFIRM_TWO_FACTOR: 'two-factor/app',
      RESET_PASSWORD: 'reset/request',
      RESET_PASSWORD_CONFIRM: (token: string) => `reset/${token}`,
      CREATE_ACCOUNT: 'apply/private',
      VALIDATE_EMAIL: 'apply/verify', // unknown
      SEND_GEOLOCATION: 'open/reveersegeolocation/get-address',
      SEND_GEOLOCATION_POSITION: 'private/geolocation/position',
      CONFIRM_ACCOUNT_REVIEW: 'user/account-review/success',
      SEND_EMAIL_CODE: 'user/private/resend-token',
      SEND_PHONE_CODE: 'user/phone/validate',
      VERIFY_EMAIL: (code: string | number) => `user/confirm/${code}`,
      VERIFY_PHONE: 'user/phone/confirm-validation',
      CREATE_CARD_PAYMENT: 'private/purchase',
      CREATE_CONTACTPAY_PAYMENT: 'private/purchase/contactpay',
      CREATE_MULTIHUB_PAYMENT: 'private/purchase/multihub',
      CREATE_USDT_PAYMENT: 'private/purchase/infura',
      CREATE_TRONGRID_PAYMENT: 'private/purchase/trongrid',
      CHECK_CARD_PAYMENT: (paymentId: number, cardholderId: string) =>
        `private/${cardholderId}/purchase/${paymentId}`,
      CHECK_CONTACTPAY_PAYMENT: (paymentId: number) =>
        `private/purchase/contactpay/${paymentId}`,
      CHECK_MULTIHUB_PAYMENT: (paymentId: number) =>
        `private/purchase/multihub/${paymentId}`,
      CHECK_USDT_PAYMENT: (paymentId: number) => `private/purchase/infura/${paymentId}`,
      CHECK_TRONGRID_PAYMENT: (paymentId: number) =>
        `private/purchase/trongrid/${paymentId}`,
      LOG_OUT: 'user/logout',
    },
    SETTINGS: {
      AVATAR: 'user/avatar',
      BIOMETRICS: {
        ENABLE: 'user/biometrical/enable',
        DISABLE: 'user/biometrical/disable',
        DISABLE_NOTIFICATION: 'user/biometrical/notification-disable',
      },
      TWO_FACTOR: {
        ENABLE: 'user/two-factor/enable',
        DISABLE: 'user/two-factor/disable',
        DISABLE_NOTIFICATION: 'user/two-factor/notification-disable',
      },
      VALIDATION: {
        UPDATE: 'user/validation/update',
        UPDATE_RESEND: (id: string | number) => `user/validation/${id}/resend`,
        UPDATE_CONFIRM: (id: string | number) => `user/validation/${id}/confirm`,
      },
      CHANGE_PASSWORD: 'user/change-password',
      GET_PHONE_CHANGE_LAST_REQUEST: 'user/change/phone/last-request',
      GET_CHANGE_REQUESTS: 'user/change/request',
      REQUEST_PHONE_CHANGE: 'user/change/request/phone',
      REQUEST_DATA_CHANGE: 'user/change-user-data/create',
      CONFIRM_PHONE_CHANGE: (requestId: string, code: string) =>
        `user/change/confirm/phone/${requestId}/${code}`,
      CONFIRM_DATA_CHANGE: (requestId: string) =>
        `user/change-user-data/confirm/${requestId}`,
      RESEND_REQUEST_PHONE_CHANGE: (requestId: string) =>
        `user/change/request/phone/${requestId}`,
      PUSH_NOTIFICATIONS_FOR_INCOMING_TRANSACTIONS: 'user/incoming-transactions',
      SET_LANGUAGE: 'user/language',
      DELETE_USER: 'user/private/user-delete',
      REPORT_PROBLEM: 'user/report-problem',
    },
    INFO: {
      GET_USER: 'user',
      GET_USER_INFO: 'user/info',
      GER_REFERRAL_CODE: 'user/referrals/get-code',
      GET_RECHARGE_CARDS: 'user/recharge-cards',
      DELETE_RECHARGE_CARD: (cardId: number) => `user/recharge-card/${cardId}`,
      UPDATE_RESTORE_ID: 'user/restore-id',
      GET_ADDRESS: 'user/address',
      HOTEL_CLICK: (cardholderId: string) => `user/hotel/click/${cardholderId}`,
    },
  },
  KRYPTOLITE_CTRL: {
    GET_AML_QUESTIONS: 'private/crypto/aml_questions',
    SEND_AML: 'private/crypto/aml_user',
    CREATE_ORDER: 'private/crypto/create',
    ESTIMATE_FEES: 'private/crypto/estimate',
    GET_ORDER_INFO: (orderId: string | number) => `private/crypto/order/${orderId}`,
    GET_ORDERS_LIST: 'private/crypto/orders',
    REGISTER: 'private/crypto/register',
    GET_SUMSUB_TOKEN: 'private/crypto/sumsubtoken',
    GET_TRADE_INFO: 'private/crypto/trade_info',
    GET_USER_INFO: 'private/crypto/user_info',
  },
  QUPPY_CTRL: {
    ACCOUNT: 'crypto/account',
    TOPUP: 'crypto/fiat/top-up',
    TOPUP_RESEND_CODE: 'crypto/fiat/top-up/resend-code',
    TOPUP_CONFIRM: 'crypto/fiat/top-up/confirm',
    VERIFY_ACCOUNT: 'crypto/account/verification',
    SEND_REQUEST: 'crypto/centralised',
    SEND_REQUEST_FIAT: 'crypto/fiat',
    CONFIRM_REQUEST: 'crypto/centralised/confirm',
    CONFIRM_REQUEST_FIAT: 'crypto/fiat/confirm',
    RECENT_RECEIVERS: 'crypto/centralised/addresses',
    RESEND_CODE: 'crypto/centralised/resend-code',
    RESEND_CODE_FIAT: 'crypto/fiat/resend-code',
    CONFIRM_EXCHANGE: (id: string | number) => `crypto/exchange/${id}/confirm`,
    EXCHANGE: 'crypto/exchange',
    GET_EXCHANGES: 'crypto/getExchangesInfo',
    GET_WALLETS: 'crypto/wallets',
    OPEN_WALLET: (currency: string) => `crypto/wallet/${currency}`,
    GET_EXCHANGES_INFO: ({
      currencyFrom,
      currencyTo,
    }: {
      currencyFrom: string;
      currencyTo: string;
    }) =>
      `crypto/exchange-info?sourceCurrencyCode=${currencyFrom}&targetCurrencyCode=${currencyTo}`,
    GET_TRANSACTIONS: ({
      currencyCodeWallet,
      take,
      offset,
    }: {
      currencyCodeWallet: string;
      take: string;
      offset: string | number;
    }) =>
      `crypto/centralised/transactions?currencyCodeWallet=${currencyCodeWallet}&take=${take}&offset=${offset}`,
    GET_TRANSACTION: (id: string | number) => `crypto/centralised/transaction/${id}`,
  },
};
