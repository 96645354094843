import oceanTransferApi from '../../api/transfer';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../common/store';
import { AnyAction } from 'redux';
import { AxiosError } from 'axios';
import {
  CreateC2CBody,
  CreateInternationalPaymentBody,
  GetTransactionsBody,
  TransferToCardBody,
} from '../../api/dto/transfers';
import { hideLoader, showLoader } from '../../../common/store/actions/app';
import { FILE_FORMAT } from '../../../common/lib/constants/constants';
import riverTransactionsApi from '../../../river/api/transactions';
import api from '../../../river/api';
import { getUserLocale } from '../../../common/store/selectors/app';
import { RiverThunkDispatch } from '../../../river/types/thunk';

export const transferToCard = (cardId: number, body: TransferToCardBody): ThunkAction<Promise<any>, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    try {
      dispatch(showLoader({ type: 'backdrop' }));
      const response = await oceanTransferApi.transferToCard(cardId, body);

      return response;
    } catch (e) {
      console.log('OCEAN: Error while transferring to card: ', e);
      throw e;
    } finally {
      dispatch(hideLoader());
    }
  };
}

export const createBankPayment = (cardId: number, body: {amount: number, details: string}): ThunkAction<Promise<any>, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    try {
      dispatch(showLoader({ type: 'backdrop' }));
      const { data: { response } } = await oceanTransferApi.bank.createPayment(cardId, body);

      return response;
    } catch (e) {
      console.log('OCEAN: Error while creating bank transfer: ', e);
      throw e;
    } finally {
      dispatch(hideLoader());
    }
  };
}

export const getBankPayment = (paymentId: number): ThunkAction<Promise<any>, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    try {
      dispatch(showLoader({ type: 'backdrop' }));

      const { data: { response } } = await oceanTransferApi.bank.getPaymentDetails(paymentId);

      return response;
    } catch (e) {
      console.log('OCEAN: Error while getting bank transfer: ', e);
      throw e;
    } finally {
      dispatch(hideLoader());
    }
  };
}

export const createC2C = (cardId: number, body: CreateC2CBody): ThunkAction<Promise<any>, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    try {
      dispatch(showLoader({ type: 'backdrop' }));
      const { data: { response } } = await oceanTransferApi.c2c.createPayment(cardId, body);

      return response;
    } catch (e) {
      console.log('OCEAN: Error while creating с2с transaction: ', e);
      throw e;
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const confirmC2C = (cardId: number, paymentId: number, amount: number): ThunkAction<Promise<any>, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    try {
      dispatch(showLoader({ type: 'backdrop' }));
      const { data: { response } } = await oceanTransferApi.c2c.confirmPayment(cardId, paymentId, { amount });

      return response;
    } catch (e) {
      const error = e as AxiosError;
      console.log('OCEAN: Error while confirming с2с transaction: ', e);
      throw error.response;
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const signC2C = (accountId: number, paymentId: number, code: string, isTwoFactorEnabled: boolean = false): ThunkAction<Promise<any>, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    try {
      dispatch(showLoader({ type: 'backdrop' }));
      const { data: { response } } = await oceanTransferApi.c2c.signPayment(accountId, paymentId, isTwoFactorEnabled ? { google2faCodeFromUser: code } : { code });

      return response;
    } catch (e) {
      console.log('OCEAN: Error while signing с2с transaction: ', e);
      throw e;
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const resendC2CCode = (cardId: number, paymentId: number): ThunkAction<Promise<any>, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    try {
      dispatch(showLoader({ type: 'backdrop' }));
      const { data: { response } } = await oceanTransferApi.c2c.resendSignCode(cardId, paymentId, {});

      return response;
    } catch (e) {
      console.log('OCEAN: Error while resending code for c2c: ', e);
      throw e;
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const createSepa = (cardId: number, form: {
  amount: number;
  beneficiary: string;
  iban: string;
  details: string;
}): ThunkAction<Promise<any>, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    try {
      dispatch(showLoader({ type: 'backdrop' }));
      const { data: { response } } = await oceanTransferApi.sepa.createPayment(cardId, form);

      return response;
    } catch (e) {
      console.log('OCEAN: Error while creating sepa transaction: ', e);

      throw e;
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const confirmSepa = (cardId: number, paymentId: number, amount: number): ThunkAction<Promise<any>, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    try {
      dispatch(showLoader({ type: 'backdrop' }));
      const { data: { response } } = await oceanTransferApi.sepa.confirmPayment(cardId, paymentId, { amount });

      return response;
    } catch (e) {
      console.log('OCEAN: Error while confirming sepa transaction: ', e);
      throw e;
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const signSepa = (cardId: number, paymentId: number, code: string, isTwoFactorEnabled: boolean = false): ThunkAction<Promise<any>, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    try {
      dispatch(showLoader({ type: 'backdrop' }));
      const { data: { response } } = await oceanTransferApi.sepa.signPayment(cardId, paymentId, isTwoFactorEnabled ? { google2faCodeFromUser: code } : { code });

      return response;
    } catch (e) {
      console.log('OCEAN: Error while signing sepa transaction: ', e);
      throw e;
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const resendSepaCode = (cardId: number, paymentId: number): ThunkAction<Promise<any>, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    try {
      dispatch(showLoader({ type: 'backdrop' }));
      const { data: { response } } = await oceanTransferApi.sepa.resendSignCode(cardId, paymentId);

      return response;
    } catch (e) {
      console.log('OCEAN: Error while resending code transaction: ', e);
      throw e;
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const createInternationalPayment = (cardId: number, body: CreateInternationalPaymentBody): ThunkAction<Promise<any>, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    try {
      dispatch(showLoader({ type: 'backdrop' }));
      const { data: { response } } = await oceanTransferApi.swift.createPayment(cardId, body);

      return response;
    } catch (e) {
      console.log('OCEAN: Error while creating international transaction: ', e);
      throw e;
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const confirmInternationalPayment = (cardId: number, paymentId: number, amount: number): ThunkAction<Promise<any>, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    try {
      dispatch(showLoader({ type: 'backdrop' }));
      const { data: { response } } = await oceanTransferApi.swift.confirmPayment(cardId, paymentId, { amount });

      return response;
    } catch (e) {
      console.log('OCEAN: Error while confirming international payment: ', e);
      throw e;
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const signInternationalPayment = (cardId: number, paymentId: number, code: string, isTwoFactorEnabled: boolean = false): ThunkAction<Promise<any>, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    try {
      dispatch(showLoader({ type: 'backdrop' }));
      const { data: { response } } = await oceanTransferApi.swift.signPayment(cardId, paymentId, isTwoFactorEnabled ? { google2faCodeFromUser: code } : { code });

      return response;
    } catch (e) {
      console.log('OCEAN: Error while signing international payment: ', e);
      throw e;
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const resendInternationalPaymentCode = (cardId: number, paymentId: number): ThunkAction<Promise<any>, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    try {
      dispatch(showLoader({ type: 'backdrop' }));
      const { data: { response } } = await oceanTransferApi.swift.resendSignCode(cardId, paymentId, {});

      return response;
    } catch (e) {
      console.log('OCEAN: Error while resending international payment code: ', e);
      throw e;
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const getTransactions = (cardId: number, {
  start, end, page, size, type, status,
}: GetTransactionsBody): ThunkAction<Promise<any>, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    try {
      const { data: { response } } = await oceanTransferApi.statement.request(cardId, {
        start,
        end,
        page,
        size,
        type,
        status,
      });

      return response;
    } catch (e) {
      console.log('OCEAN: Error while get transactions: ', e);
      throw e;
    }
  };
};

export const downloadTransaction = (
  cardId: number,
  transactionId: string,
): ThunkAction<Promise<any>, RootState, unknown, AnyAction> => {
  return async (dispatch, getState: () => RootState) => {
    const state = getState(),
      language = getUserLocale(state);
    try {
      const response = await oceanTransferApi.statement.downloadTransaction(cardId, transactionId, { language });

      if (response.status === 200 && response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: `application/${FILE_FORMAT.PDF}` }));
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.setAttribute('download', `statement.${FILE_FORMAT.PDF}`);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(url);
      } else {
        throw response.status;
      }

      return response;
    } catch (e) {
      console.log('OCEAN: Error while downloading transaction by id: ', e);
      throw e;
    }
  };
};

export const downloadInvoice = (cardId: number, amount: number, language?: string): ThunkAction<Promise<any>, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const response = await oceanTransferApi.invoice.download(cardId, { amount, language });

      if (response.status === 200 && response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: `application/${FILE_FORMAT.PDF}` }));
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.setAttribute('download', `statement.${FILE_FORMAT.PDF}`);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(url);
      } else {
        throw response.status;
      }

      return response;
    } catch (e) {
      console.log('OCEAN: Error while downloading invoice by id: ', e);
      throw e;
    } finally {
      dispatch(hideLoader());
    }
  }
};
