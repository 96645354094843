import { Flex, Typography } from 'antd';
import { CustomCardContainer } from 'common/components/UI';
import CodeInput from 'common/components/UI/CodeInput/CodeInput';
import { hideLoader, showLoader } from 'common/store/actions/app';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { login } from '../SignIn/actions';
import { AppDispatch } from 'common/store';
import { useMessage } from 'common/lib/hooks/useMessage/useMessage';
import { handleErrorWithCommonFallback } from 'common/lib/utils/helpers';
import { useIntl } from 'react-intl';
import { ROUTES } from 'common/components/AppRouter/AppRouter';
import classes from './TwoFactor.module.scss';
import { APP } from 'common/lib/constants/constants';

const TwoFactor = () => {
  const { state } = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { formatMessage: t } = useIntl();
  const { showError } = useMessage();

  const onCodeEntered = (code: string) => {
    dispatch(showLoader());
    dispatch(login({ email: state.email, password: state.password, twoFactorCode: code }))
      .then((response: any) => {
        if (response?.route) navigate(response.route);
      })
      .catch(({ response }: any) => {
        handleErrorWithCommonFallback(response, t, showError);
      })
      .finally(() => {
        dispatch(hideLoader());
      });
  };

  if (!state.email && !state.password) {
    navigate(ROUTES.SIGN_IN.path);
  }

  return (
    <div className={classes.wrapper}>
      <Flex flex={1} justify="center" align="center">
        <CustomCardContainer className={classes.card}>
          <Typography.Text className={classes.companyName}>{APP.NAME}</Typography.Text>
          <Typography.Text className={classes.title}>
            {t({ id: 'screens.auth.two.factor.title' })}
          </Typography.Text>
          <Typography.Paragraph className={classes.desc}>
            {t({ id: 'screens.auth.two.factor.legend' })}
          </Typography.Paragraph>
          <CodeInput onEnter={onCodeEntered} />
        </CustomCardContainer>
      </Flex>
    </div>
  );
};

export default TwoFactor;
