import React from 'react';
import { LockOutlined } from '@ant-design/icons';
import { Snowflake } from 'common/components/icons';
import { Carousel, Flex, Space, Typography } from 'antd';
import {
  ActionButton,
  BackButton,
  GridContainer,
  Card as CardComponent,
  BlackButton,
} from 'common/components/UI';
import Requisites from './blocks/Requisites/Requisites';
import ManageCard from './blocks/ManageCard/ManageCard';

import { UniversalCard } from 'common/types/cards';
import { CARD_UNIVERSAL_STATUS, PLANS } from 'common/lib/constants/constants';
import classes from './Cards.module.css';
import Transactions from '../../../../ocean/pages/Cards/drawers/Transactions/Transactions';
import { useIntl } from 'react-intl';
import OtherBlock from '../AccountLayout/blocks/Other/Other';
import IBANRequisites from '../AccountLayout/blocks/Requisites/Requisites';

export type CardsLayoutProps = {
  planName?: keyof typeof PLANS;
  currentCardIndex: number;
  cards: UniversalCard[];
  color: string;
  options?: React.ReactElement;
  applicationAddress?: string;
  isOpenTransactions?: boolean;
  showError?: (text: string) => unknown;
  showSuccess?: (text: string) => unknown;
  onFreeze: () => unknown;
  onUnfreeze: () => unknown;
  onBlock?: () => unknown;
  onChangeSelectedCard?: (index: number) => unknown;
  onOpenTransactions?: () => unknown;
  onCloseTransactions?: () => unknown;
  onEnroll?: () => unknown;
  onUnenroll?: () => unknown;
  onActivate?: () => unknown;
  onReplace?: () => unknown;
  onStatementDownload?: ({
    dateRange,
    format,
  }: {
    dateRange: Array<string>;
    format: 'pdf' | 'csv';
  }) => void | Promise<any>;
};

const CardsLayout: React.FC<CardsLayoutProps> = ({
  planName,
  cards,
  currentCardIndex,
  color,
  options,
  applicationAddress,
  isOpenTransactions,
  showError,
  showSuccess,
  onFreeze,
  onUnfreeze,
  onBlock,
  onChangeSelectedCard,
  onOpenTransactions,
  onCloseTransactions,
  onEnroll,
  onUnenroll,
  onActivate,
  onReplace,
  onStatementDownload
}) => {
  const { formatMessage: t } = useIntl();
  const currentCard = cards[currentCardIndex];

  const getShortNumber = (number: string) => {
    return number.slice(-4) || '';
  };

  const isOcean = planName === PLANS.OCEAN;
  const isFrozen = currentCard.universalCardStatus === CARD_UNIVERSAL_STATUS.FROZEN;
  const isBlocked = currentCard.universalCardStatus === CARD_UNIVERSAL_STATUS.BLOCKED;
  const isClosed = currentCard.universalCardStatus === CARD_UNIVERSAL_STATUS.CLOSED;
  const isSuspended = currentCard.universalCardStatus === CARD_UNIVERSAL_STATUS.SUSPENDED;
  const isApplication =
    currentCard.universalCardStatus === CARD_UNIVERSAL_STATUS.APPLICATION; 
  const isInactive = currentCard.universalCardStatus === CARD_UNIVERSAL_STATUS.INACTIVE

  return (
    <GridContainer>
      <BackButton />
      <div className={classes.center}>
        <Carousel
          dots={{ className: classes.dots }}
          style={{ overflow: 'visible', width: '396px' }}
          initialSlide={currentCardIndex}
          rootClassName={classes.carousel}
          centerMode={cards.length > 1}
          slidesToShow={1}
          draggable
          infinite={false}
          focusOnSelect
          afterChange={onChangeSelectedCard}>
          {cards.map((card, index) => (
            <span key={`${card.id}-${index}`}>
              <div className={classes.cards}>
                <CardComponent
                  size="l"
                  status={card.universalCardStatus}
                  shortNumber={getShortNumber(card.number)}
                  virtual={card.isVirtual}
                  bgColor={color}
                  selected={index === currentCardIndex}
                />
              </div>
            </span>
          ))}
        </Carousel>
      </div>
      {!isBlocked && !isSuspended && !isClosed && !isApplication && !isInactive && (
        <Space className={classes.fullWidth} direction="vertical" size={24}>
          <Flex vertical gap={8}>
            <Flex gap={8}>
              <ActionButton
                id={isFrozen ? 'btn__unfreeze' : 'btn__freeze'}
                text={
                  isFrozen
                    ? t({ id: 'river.views.card.freezing.button.unfreeze' })
                    : t({ id: 'river.views.card.freezing.button.freeze' })
                }
                icon={<Snowflake style={{ fontSize: 24 }} />}
                onClick={isFrozen ? onUnfreeze : onFreeze}
              />
              {onBlock && (
                <ActionButton
                  id='btn__block'
                  text={t({ id: 'buttons.block' })}
                  icon={<LockOutlined style={{ fontSize: 24 }} />}
                  onClick={onBlock}
                />
              )}
            </Flex>
            {onEnroll && onUnenroll && (
              <ActionButton
                id='btn__3ds'
                text={
                  currentCard.enrolled
                    ? t({ id: 'card.3ds.reset' })
                    : t({ id: 'card.3ds.set' })
                }
                icon={<LockOutlined style={{ fontSize: 24 }} />}
                onClick={currentCard.enrolled ? onUnenroll : onEnroll}
              />
            )}
          </Flex>
          {onOpenTransactions && (
            <Transactions
              cardId={Number(currentCard.id)}
              showError={showError}
              showSuccess={showSuccess}
              onClose={onCloseTransactions}
              onOpen={onOpenTransactions}
              isOpen={isOpenTransactions}
            />
          )}
          {isOcean && <IBANRequisites iban={currentCard.iban} showError={showError}
            showSuccess={showSuccess}/>}
          {/* if card is plastic hide requisites */}
        {currentCard.isVirtual || isOcean ?  <Requisites
            cardNumber={currentCard.number}
            cvv={String(currentCard.cvv)}
            expDate={currentCard.expDate}
            showError={showError}
            showSuccess={showSuccess}
          />
          : null}
          {options && <ManageCard options={options} />}
        </Space>
      )}
      {isApplication && (
        <Flex vertical align="center" justify="center" gap={36}>
          <Typography.Text className={classes.address}>
            {t({ id: 'card.application.address' })}
            <br />
            {applicationAddress}
          </Typography.Text>
          <BlackButton type="primary" onClick={onActivate}>
            {t({ id: 'card.application.activate' })}
          </BlackButton>
        </Flex>
      )}
      {isBlocked && onReplace && (
        <Flex vertical align="center" justify="center">
          <BlackButton type="primary" onClick={onReplace}>
            {t({ id: 'card.blocked.replace' })}
          </BlackButton>
        </Flex>
      )}
      {onStatementDownload &&
        <OtherBlock
          fromCard
          onStatementDownload={onStatementDownload}
        />
      }
    </GridContainer>
  );
};

export default CardsLayout;
