import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classes from './Transactions.module.scss';
import { Flex, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useMessage } from 'common/lib/hooks/useMessage/useMessage';
import { AppDispatch } from 'common/store';
import { getCurrentAccount } from '../../../../store/selectors';
import { Transaction, TransactionDetailsType } from '../../../../types/transactions';
import {
  downloadTransaction,
  getHoldById,
  getHolds,
  getTransactionById,
  getTransactions,
} from '../../../../store/actions/transactions';
import {
  ALL_CONFIG,
  Filter,
  FILTERS,
  FILTERS_CONFIG,
  MONTH_CONFIG,
  TabsType,
  TRANSACTION_COUNT,
} from './model/constants';
import moment from 'moment';
import { RIVER_TRANSACTION_STATUS } from '../../../../lib/constants/values';
import { TransactionItem } from './TransactionsList';
import { hideLoader, showLoader } from 'common/store/actions/app';
import DrawerTransactions from './DrawerTransactions';
import { ActivityIndicator, GreyContainer } from 'common/components/UI';
import { handleLangFormat } from '../../../../../common/lib/utils/lang';
import { getUserLocale } from '../../../../../common/store/selectors/app';

type TransactionsProps = {
  isOpen?: boolean;
  onClose?: () => unknown;
  onOpen?: () => unknown;
};

const Transactions: React.FC<TransactionsProps> = ({ isOpen, onClose, onOpen }) => {
  const { formatMessage: t } = useIntl();
  const currentAccount = useSelector(getCurrentAccount);
  const dispatch = useDispatch<AppDispatch>();
  const { showError, showSuccess } = useMessage();

  const [loading, setLoading] = useState<boolean>(false);
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [canLoadMore, setCanLoadMore] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [holds, setHolds] = useState<Transaction[]>([]);
  const [monthTransactions, setMonthTransactions] = useState<Transaction[]>([]);
  const [monthHolds, setMonthHolds] = useState<Transaction[]>([]);
  const [activeFilter, setActiveFilter] = useState<Filter>(FILTERS.ALL);
  const [activeTransaction, setActiveTransaction] = useState<TransactionDetailsType>();
  const lang = handleLangFormat(useSelector(getUserLocale));

  useEffect(() => {
    moment.locale(lang);
  }, [lang])

  const fetchMonthData = useCallback(async () => {
    if (!currentAccount) return;

    try {
      setLoading(true);
      const { transactions: transactionsList } = await dispatch(
        getTransactions(currentAccount, {
          ...MONTH_CONFIG,
        }),
      );
      const { transactions: holdsList } = await dispatch(
        getHolds(currentAccount, { ...MONTH_CONFIG }),
      );
      setMonthTransactions(transactionsList);
      setMonthHolds(holdsList);
    } catch (error) {
      console.log('error fetching: ', error);
    } finally {
      setLoading(false);
    }
  }, [currentAccount, activeFilter]);

  const updateData = async (filter?: Filter) => {
    if (!currentAccount) return;

    const refreshHolds = !filter || filter === FILTERS.HOLD;
    const refreshTransactions = !filter || filter !== FILTERS.HOLD;
    const nextPage = page + 1;
    const activeFilterConfig = (activeFilter && activeFilter !== FILTERS.HOLD) ? FILTERS_CONFIG[activeFilter] : {};

    try {
      setLoading(true);
      if (refreshTransactions) {
        const { transactions: transactionsList, totalPages } = await dispatch(
          getTransactions(currentAccount, {
            ...ALL_CONFIG,
            ...activeFilterConfig,
            page: nextPage,
          }),
        );
        setCanLoadMore(totalPages - 1 > nextPage);
        setTransactions((prev) => [...prev, ...transactionsList]);
      }

      if (refreshHolds) {
        const { transactions: holdsList, totalPages } = await dispatch(
          getHolds(currentAccount, {
            ...ALL_CONFIG,
            page: nextPage,
          }),
        );
        filter && setCanLoadMore(totalPages - 1 > nextPage);
        setHolds((prev) => [...prev, ...holdsList]);
      }
    } catch (error) {
      console.log('error fetching: ', error);
    } finally {
      setPage(nextPage);
      setLoading(false);
    }
  };

  const fetchData = useCallback(
    async (filter?: Filter) => {
      if (!currentAccount) return;

      const refreshHolds = !filter || filter === FILTERS.HOLD;
      const refreshTransactions = !filter || filter !== FILTERS.HOLD;
      const activeFilterConfig = (activeFilter && activeFilter !== FILTERS.HOLD) ? FILTERS_CONFIG[activeFilter] : {};

      try {
        setLoading(true);
        if (refreshTransactions) {
          const { transactions: transactionsList, totalSize } = await dispatch(
            getTransactions(currentAccount, {
              ...ALL_CONFIG,
              ...activeFilterConfig,
            }),
          );
          setCanLoadMore(totalSize > TRANSACTION_COUNT);
          setTransactions(transactionsList);
        }
        if (refreshHolds) {
          const { transactions: holdsList, totalSize } = await dispatch(
            getHolds(currentAccount, {
              ...ALL_CONFIG,
            }),
          );
          filter && setCanLoadMore(totalSize > TRANSACTION_COUNT);
          setHolds(holdsList);
        }
      } catch (error) {
        console.log('error fetching: ', error);
      } finally {
        setPage(0);
        setLoading(false);
      }
    },
    [currentAccount, activeFilter],
  );

  const filteredData = useMemo(() => {
    if (activeFilter === FILTERS.HOLD) return holds || [];
    return transactions || [];
  }, [activeFilter, holds, transactions]);

  const groupedData = useMemo(() => {
    const grouped = filteredData.reduce((acc: TransactionItem[], transaction) => {
      const date = moment(transaction.created, 'DD-MM-YYYY HH:mm:ss').format(
        'DD-MM-YYYY',
      );

      let group = acc.find((item) => item.date === date);
      if (!group) {
        group = { date, transactions: [] };
        acc.push(group);
      }

      group.transactions.push(transaction);
      return acc;
    }, []);

    return grouped;
  }, [filteredData]);

  const isEmpty = filteredData.length === 0;

  const currentMonthSpendingAmount = useMemo(() => {
    return monthTransactions
      ? monthTransactions
          .filter((t) => t.amount < 0 && t.status === RIVER_TRANSACTION_STATUS.APPROVED)
          .reduce((sum, cur) => sum + Math.abs(cur.amount), 0)
      : 0;
  }, [monthTransactions]);

  const currentMonthHoldsAmount = useMemo(() => {
    return monthHolds
      ? monthHolds
          .filter((t) => t.status === RIVER_TRANSACTION_STATUS.PENDING)
          .reduce((sum, cur) => sum + Math.abs(cur.amount), 0)
      : 0;
  }, [monthHolds]);

  const onLoadMore = () => {
    updateData(activeFilter);
  };

  useEffect(() => {
    fetchData(activeFilter);
  }, [fetchData, activeFilter]);

  useEffect(() => {
    fetchMonthData();
  }, []);

  const onChangeFilter = (activeTab: TabsType) => {
    setActiveFilter(activeTab);
  };

  const onExportPdf = useCallback(async (transactionId?: string) => {
    if (!transactionId || !currentAccount) return;
    showSuccess(t({id: "river.views.statement.downloading"}), 'toast__download-in-progress')
    setExportLoading(true);
    try {
      await dispatch(downloadTransaction(currentAccount, transactionId));
    } catch (err) {
      showError(t({ id: 'common.message.error' }));
    } finally {
      setExportLoading(false);
    }
  }, []);

  const onDataSelect = useCallback(
    async (id: string) => {
      if (!currentAccount) return;

      let response;
      try {
        dispatch(showLoader({ type: 'backdrop' }));
        if (activeFilter === FILTERS.HOLD) {
          const responseBody = await dispatch(getHoldById(currentAccount, id));
          response =
            responseBody.holdTransaction ?? responseBody.authorizationTransaction;
        } else {
          const responseBody = await dispatch(getTransactionById(currentAccount, id));
          response = responseBody;
        }
      } catch (e) {
        showError(t({ id: 'common.message.error' }));
      } finally {
        dispatch(hideLoader());
      }

      setActiveTransaction(response);
    },
    [activeFilter, currentAccount],
  );

  return (
    <>
      <GreyContainer
        id='transactions-container'
        title={t({ id: 'transactions.title' })}
        onClick={onOpen}
        className={classes['transactions-block']}>
        <Flex justify="space-between">
          <Typography.Text className={classes.text}>
            {t(
              { id: 'transactions.spent' },
              { month: t({ id: `calendar.month.${new Date().getMonth()}` }) },
            )}
          </Typography.Text>
          {loading ? (
            <ActivityIndicator size="small" />
          ) : (
            <Typography.Text className={classes.balance}>
              {currentMonthSpendingAmount} €
            </Typography.Text>
          )}
        </Flex>
      </GreyContainer>
      <DrawerTransactions
        isOpen={isOpen}
        isEmpty={isEmpty}
        onClose={onClose}
        loading={loading}
        exportLoading={exportLoading}
        canLoadMore={canLoadMore}
        onLoadMore={onLoadMore}
        activeTransaction={activeTransaction}
        setActiveTransaction={setActiveTransaction}
        onDataSelect={onDataSelect}
        onChangeFilter={onChangeFilter}
        onExportPdf={onExportPdf}
        groupedData={groupedData}
        currentMonthSpendingAmount={currentMonthSpendingAmount}
        currentMonthHoldsAmount={currentMonthHoldsAmount}
      />
    </>
  );
};

export default Transactions;
