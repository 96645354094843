import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import classes from './BackButton.module.css';
import { useNavigate } from 'react-router';
import { useIntl } from 'react-intl';

type BackButtonProps = {
  path?: string;
};

const BackButton: React.FC<BackButtonProps> = ({ path }) => {
  const navigate = useNavigate();
  const { formatMessage: t } = useIntl();

  return (
    <div
      id='btn__back'
      className={classes.wrapper}
      onClick={() => {
        if (path) navigate(path);
        else navigate(-1);
      }}>
      <div className={classes.button}>
        <ArrowLeftOutlined style={{ fontSize: 20 }} />
        <Typography.Text className={classes.text}>{t({ id: 'buttons.back' })}</Typography.Text>
      </div>
    </div>
  );
};

export default BackButton;
