import { CARD_STATUS, CARD_TYPE } from 'river/lib/constants/values';
import cardApi from 'river/api/card';
import { Card } from 'river/types';

export const replaceCard = async (
  accountId: number | string,
  card: Card,
  deliveryType = '',
) => {

  if (card && card.status === CARD_STATUS.BLOCKED) {
    try {
      if (card.type === CARD_TYPE.PHYSICAL) {
        const response = await cardApi.replacePCCard(accountId, card.id, deliveryType);
        return response.data.response;
      } else if (card.type === CARD_TYPE.VIRTUAL) {
        const response = await cardApi.replaceVCCard(accountId, card.id);
        return response.data.response;
      }
    } catch (e) {
      throw new Error();
    }
  }
};
