export const IS_DEV = true;
export const APP = Object.freeze({
    NAME: 'WestStein',
});
export const THEME = Object.freeze({
    LIGHT: 'light',
    DARK: 'dark',
});

export const PLANS = {
    RIVER: 'RIVER',
    OCEAN: 'OCEAN',
    SEA: 'SEA',
} as const; 

export const FILE_FORMAT = {
    PDF: 'pdf',
    CSV: 'csv',
} as const;

export const CARD_UNIVERSAL_STATUS = {
    ACTIVE: 'ACTIVE',
    BLOCKED: 'BLOCKED',
    FROZEN: 'FROZEN',
    APPLICATION: 'APPLICATION',
    CLOSED: 'CLOSED',
    SUSPENDED: 'SUSPENDED',
    INACTIVE: 'INACTIVE',
} as const;