import React, { useEffect, useState } from 'react';
import classes from './Notification.module.scss';
import { Flex, Typography } from 'antd';
import moment from 'moment/moment';
import { GreyContainer } from 'common/components/UI';

type NotificationProps = {
  title: string,
  text: string,
  date: string,
  unread?: boolean
}

const { Text } = Typography;

const UnreadIcon = <div className={classes.unreadIcon}></div>;

const Notification: React.FC<NotificationProps> = ({ title, text, date, unread }) => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    countLines();
  }, []);

  const handleClick = () => {
    setOpen(!open);
  };

  const countLines = () => {
    const text = document.getElementById('notification-text') as HTMLElement;
    const divHeight = text.offsetHeight;
    const lineHeight = 22;
    const lines = divHeight / lineHeight;

    const notification = document.getElementById('notification') as HTMLElement;
    if (lines >= 4)
      notification.style.cursor = 'pointer';
  };

  return (
    <GreyContainer
      id="notification"
      className={classes.notification}
      title={title}
      onClick={handleClick}
      action={unread ? UnreadIcon : undefined}
    >
      <Flex vertical gap={16}>
        <Text
          id="notification-text"
          className={`${classes.notification__text} ${open ? classes.notification__text_open : ''}`}
        >
          {text}
        </Text>
        <Text style={{ opacity: 0.6 }}>{moment(date, 'DD-MM-YYYY hh:mm:ss').format('DD MMMM YYYY, hh:mm a')}</Text>
      </Flex>
    </GreyContainer>
  );
};

export default Notification;