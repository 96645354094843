import { Action, Dispatch } from 'redux';
import userApi from 'common/api/user';
import { getUserFullInfo } from 'common/store/actions/user';
import { ThunkAction } from 'redux-thunk';
import { RootState } from 'common/store';
import { ROUTES } from 'common/components/AppRouter/AppRouter';
import { message } from 'antd';
import { initRiver } from 'river/store/actions';

export const login = ({ email, password, twoFactorCode }: {
  email: string;
  password: string;
  twoFactorCode?: string
}): ThunkAction<Promise<unknown>, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    dispatch({ type: 'App/SHOW_LOADER' });
    try {
      await userApi.auth.login(email, password, twoFactorCode);
      const {user, cards} = await dispatch(getUserFullInfo());

      await dispatch(initRiver());

      // Проверка на статус

      // Проверка на 2FA

      let route = '';
      if (!user.verified) route = ROUTES.VERIFY_EMAIL.path;
      else if (!user.phoneVerified) route = ROUTES.VERIFY_PHONE.path;
      else route = ROUTES.DASHBOARD.path;
      return { user, cards, route };
    } catch (error: any) {
      throw error;
    } finally {
      dispatch({ type: 'App/HIDE_LOADER' });
    }
  };
};
