import React from 'react';
import { Flex, Layout, Typography } from 'antd';
import classes from './Header.module.scss';
import { combineClasses } from 'common/lib/utils/helpers';
import { APP } from 'common/lib/constants/constants';
import { ROUTES } from 'common/components/AppRouter/AppRouter';
import { Link } from 'react-router-dom';
import { BellOutlined, LogoutOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../store/actions/user';
import { AppDispatch, RootState } from '../../../store';
import { UserAvatar } from '../../UI';

interface HeaderProps extends React.ComponentProps<typeof Layout.Header> {
  className?: string;
}

const Header: React.FC<HeaderProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();

  const url = useSelector((state: RootState) => state.user.avatar);

  const onLogout = () => {
    dispatch(logout());
  };

  return (
    <Layout.Header
      {...props}
      className={combineClasses(classes.container, props.className)}>
      <Link to={ROUTES.DASHBOARD.path}>
        <div className={classes.titleContainer}>
          <Typography.Text id='header-logo' className={classes.title}>{APP.NAME}</Typography.Text>
        </div>
      </Link>
      <Flex gap={16} align="center">
        <Link id='btn__notifications' to={ROUTES.NOTIFICATIONS.path} className={classes.button}>
          <BellOutlined />
        </Link>
        <Link to={ROUTES.PROFILE.path} className={classes.centered}>
          <UserAvatar url={url} size={48} />
        </Link>
        <Flex id='btn__logout' onClick={onLogout} className={classes.button}>
          <LogoutOutlined />
        </Flex>
      </Flex>
    </Layout.Header>
  );
};

export default Header;
